import React, { useState, useEffect } from 'react'
import Banner from "../Banner";
import Form from '../Form';
import WOW from 'wowjs';
import { Helmet } from "react-helmet";

function ContactUs() {
    const [settingsData, setSettingsData] = useState([]);

    useEffect(() => {
        const cachedData = localStorage.getItem('settingsData');
        if (cachedData) {
            try {
                const parseData = JSON.parse(cachedData);
                setSettingsData(parseData);
            } catch (error) {
                console.error("Error Parsing Data", error);
                localStorage.removeItem('settingsData');
            }
        }
    }, []);

    
    // Page Title 
    const titleSettings = settingsData.find(settings => settings.meta_key === 'setting_contact_title');
    const pageTitle = titleSettings ? titleSettings.meta_value : '';
    // Page Banner 
    const bannerSettings = settingsData.find(settings => settings.meta_key === 'setting_contact_form_banner');
    const pageBanner = bannerSettings ? bannerSettings.meta_value : '';
    // Phone Number 
    const phoneSetting = settingsData.find(setting => setting.meta_key === 'setting_contact_phone');
    const phoneNumber = phoneSetting ? phoneSetting.meta_value : '';
    // Phone Number 
    const mobileSetting = settingsData.find(setting => setting.meta_key === 'setting_contact_mobile');
    const mobileNumber = mobileSetting ? mobileSetting.meta_value : '';
    // E-mail Address 
    const emailSetting = settingsData.find(setting => setting.meta_key === 'setting_contact_email_address');
    const emailAddress = emailSetting ? emailSetting.meta_value : '';
    // Address 
    const addressSetting = settingsData.find(setting => setting.meta_key === 'setting_contact_location');
    const contactAddress = addressSetting ? addressSetting.meta_value : '';
    
    // Meta Tags 
    const metaTitleSetting = settingsData.find(setting => setting.meta_key === 'setting_contact_meta_title');
    const metaTitle = metaTitleSetting ? metaTitleSetting.meta_value : '';

    const metaDescriptionSetting = settingsData.find(setting => setting.meta_key === 'setting_contact_meta_description');
    const metaDescription = metaDescriptionSetting ? metaDescriptionSetting.meta_value : '';

    const metaKeywordsSetting = settingsData.find(setting => setting.meta_key === 'setting_contact_meta_keywords');
    const metaKeywords = metaKeywordsSetting ? metaKeywordsSetting.meta_value : '';
    useEffect(() => {
        window.scrollTo(0, 0);
        const wow = new WOW.WOW({ live: false });
        wow.init();
    }, []);
    return (
        <>
            <Helmet>
                <title>Contact Us</title>
                <meta name="title" content={metaTitle} />
                <meta name="description" content={metaDescription} />
                <meta name="keywords" content={metaKeywords} />
                <meta name="image" content={process.env.REACT_APP_IMG_PATH+"uploads/"+pageBanner} />
            </Helmet>
            <Banner imgUrl={process.env.REACT_APP_IMG_PATH+"uploads/"+pageBanner} heading="Contact Outsourced Book Keeping."/>
            {/* What Does a Bookkeeper Do? */}
            <section
                className="wow fadeInUp"
                data-wow-delay="200ms"
                style={{ paddingTop: "200px !important" }}
            >
                <div className="container">
                    <div className='row'>
                        <h2 className='text-center main-head'>Contact Us</h2>
                        {/* Form Component */}
                        <Form />
                        <div className='col-xl-6 col-md-12 col-12 wow fadeIn' data-wow-delay="600ms">
                            <div className="container p-4 mt-4 pt-5">
                                <div className='row'>
                                    <div className='col-md-12 contact-us'>
                                        <div className='d-flex pb-4' style={{ gap: '40px' }}>
                                            <img className='align-self-center' src='img/icon/phone.png' />
                                            <h3 className='text-main'>{phoneNumber} <br /> {mobileNumber}</h3>
                                        </div>
                                        <div className='d-flex pb-4' style={{ gap: '40px' }}>
                                            <img className='align-self-center' src='img/icon/address.png' />
                                            <h3 className='text-main'>{contactAddress}</h3>
                                        </div>
                                        <div className='d-flex pb-4' style={{ gap: '40px' }}>
                                            <img className='align-self-center' src='img/icon/mail.png' />
                                            <h3 className='text-main'>{emailAddress}</h3>
                                        </div>
                                    </div>
                                </div>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d444668.69015075424!2d-98.84402003898975!3d29.45876540129735!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x865c58af04d00eaf%3A0x856e13b10a016bc!2sSan%20Antonio%2C%20TX%2C%20USA!5e0!3m2!1sen!2sin!4v1712663204103!5m2!1sen!2sin" width="100%" height="400" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactUs
