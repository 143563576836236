import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './pages/Header';
const baseURL = 'https://outsourcebookkeeping.us/api/get-settings'
const baseLocalURL = 'http://localhost/projects/Outsourcedbookkeeping/eosa-api/get-settings'

export default function SiteSettings() {
    const [settingsData, setSettingsData] = useState(null);
    useEffect(() => {
        const fetchSettingsData = async () => {
            try {
                const response = await axios.post(baseLocalURL, {});
                const data = response.data;
                setSettingsData(data.settings);
                // Save data to localStorage
                localStorage.setItem('settingsData', JSON.stringify(data.settings));
            } catch (error) {
                console.error('Error Fetching Data:', error);
            }
        };
        const cachedData = localStorage.getItem('settingsData');
        if (cachedData) {
            try {
                const parsedData = JSON.parse(cachedData);
                setSettingsData(parsedData);
            } catch (error) {
                console.error('Error parsing cached data:', error);
                localStorage.removeItem('settingsData');
                fetchSettingsData();
            }
        } else {
            fetchSettingsData();
        }
    }, []);

    return (
        <div>
            <Header settingsData={settingsData} />
        </div>
    )
}
