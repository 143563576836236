import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import $ from "jquery";
import ReCAPTCHA from 'react-google-recaptcha';
import { ThreeDots } from 'react-loader-spinner';

var baseURL = 'https://outsourcebookkeeping.us/api/form-submit'
var baseLocalURL = 'http://localhost/projects/Outsourcedbookkeeping/eosa-api/form-submit'
var datanew = new FormData(($("#form1")[0]))
export default function Form() {
    const [settingsData, setSettingsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        datanew = new FormData(($("#form1")[0]))
        try {
            const response = await axios.post(baseURL, datanew);
            setIsLoading(false)
            if (response.data.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: response.data.message
                });
            } else if (response.data.status === 103) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Warning',
                    text: response.data.message
                })
            } else if (response.data.status === 104) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: response.data.message
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: response.data.message
                });
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Error submitting form:', error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "An error Occured!!! Please try again later"
            });
        }
    };
    
    useEffect(() => {
        const cachedData = localStorage.getItem('settingsData')
        if (cachedData) {
            try {
                const parseData = JSON.parse(cachedData);
                setSettingsData(parseData);
            } catch(error) {
                console.error("Error Parsing Data", error);
                localStorage.removeItem('settingsData');
            }
        }
    })
    
    // Page Title 
    const contactMailSettings = settingsData.find(settings => settings.meta_key === 'setting_contact_email_to');
    const contactMail = contactMailSettings ? contactMailSettings.meta_value : '';

    return (
        <>
            <div className="col-lg-6 col-md-9 wow fadeIn p-0" data-wow-delay="600ms">
                <div className="container p-0">
                    <form id="form1" onSubmit={handleSubmit} className="bg-white p-4 rounded" method='post' encType='multipart/form-data'>
                        <div className="text-center pb-4">
                            <h3 className="mb-2 mt-2">Let’s Start a Conversation</h3>
                            <p className="text-main">We’d love to discuss how to elevate your business</p>
                        </div>
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control border border-success"
                                placeholder="Name"
                                name='name'
                                value={datanew.name}
                                required
                            />
                        </div>
                        <div className='row'>
                            <div className="mb-3 col-md-6">
                                <input
                                    type="text"
                                    className="form-control border border-success"
                                    placeholder="Email"
                                    name='email'
                                    value={datanew.email}
                                    required
                                />
                            </div>
                            <div className="mb-3 col-md-6">
                                <input
                                    type="text"
                                    className="form-control border border-success"
                                    placeholder="Phone Number"
                                    name='phoneNumber'
                                    value={datanew.phoneNumber}
                                    required
                                />
                            </div>
                        </div>
                        <input type='hidden' name='toMail' value={contactMail}/>
                        <div className="mb-3">
                            <textarea
                                className="form-control border border-success"
                                rows={4}
                                placeholder="Message"
                                name='message'
                                value={datanew.message}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="color-main h4"> My Main Concerns Are: </label>
                            <div className="d-flex flex-wrap">
                                <div className="form-check me-3 mb-2">
                                    <input type="checkbox" className="form-check-input" name='concerns[]' value='On time Reports' id="val1" />
                                    <label className="form-check-label" htmlFor="val1">On-time reports</label>
                                </div>
                                <div className="form-check me-3 mb-2">
                                    <input type="checkbox" className="form-check-input" name='concerns[]' value='Response time' id="val2" />
                                    <label className="form-check-label" htmlFor="val2">Response Time</label>
                                </div>
                                <div className="form-check me-3 mb-2">
                                    <input type="checkbox" className="form-check-input" name='concerns[]' value='Compliance' id="val3" />
                                    <label className="form-check-label" htmlFor="val3">Compliance</label>
                                </div>
                                <div className="form-check mb-2">
                                    <input type="checkbox" className="form-check-input" name='concerns[]' value='Transfer of Books' id="val4" />
                                    <label className="form-check-label" htmlFor="val4">Transfer of books</label>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="color-main h4">I heard about you from</label>
                            <div className="d-flex flex-wrap">
                                <div className="form-check me-3 mb-2">
                                    <input type="checkbox" name='referralSources[]' value='Online Search Result' className="form-check-input" id="value1" />
                                    <label className="form-check-label" htmlFor="value1">Online Search Result</label>
                                </div>
                                <div className="form-check me-3 mb-2">
                                    <input type="checkbox" name='referralSources[]' value='Podcast' className="form-check-input" id="value2" />
                                    <label className="form-check-label" htmlFor="value2">Podcast</label>
                                </div>
                                <div className="form-check me-3 mb-2">
                                    <input type="checkbox" name='referralSources[]' value='Word of Mouth' className="form-check-input" id="value3" />
                                    <label className="form-check-label" htmlFor="value3">Word of Mouth</label>
                                </div>
                                <div className="form-check mb-2">
                                    <input type="checkbox" name='referralSources[]' value='Email Campaign' className="form-check-input" id="value4" />
                                    <label className="form-check-label" htmlFor="value4">Email Campaign</label>
                                </div>
                            </div>
                        </div>
                        {/* Add ReCAPTCHA component */}
                        <div className="pb-2">
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                            />
                        </div>
                        {isLoading && (
                            <ThreeDots
                                visible={true}
                                height="80"
                                width="80"
                                color="#008080"
                                radius="9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        )}
                        <div className="pt-3 pb-3">
                            <button type="submit" className="form-control btn btn-main">Submit</button>
                        </div>
                    </form>

                </div>
            </div>
        </>
    )
}
