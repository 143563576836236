import React, { useState, useEffect } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import Banner from '../Banner';
import axios from 'axios';
import WOW from 'wowjs';
import { Helmet } from "react-helmet";



const baseLocalURL = "http://localhost/projects/Outsourcedbookkeeping/eosa-api/get-faqs";

export default function Faqs() {
    const [faqsData, setFaqsData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
        const wow = new WOW.WOW({ live: false });
        wow.init();
    }, []);

    useEffect(() => {
        const fetchFaqs = async () => {
            try {
                const response = await axios.post(baseLocalURL);
                const data = response.data;
                console.log(data.faqs);
                setFaqsData(data.faqs);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching data", error);
                setIsLoading(false);
            }
        };
        fetchFaqs();
    }, [])
    return (
        <>
            <Helmet>
                <title>Gallery</title>
                <meta name="title" content='Gallery' />
                <meta name="description" content='Description' />
                <meta name="keywords" content='Keyword' />
                <meta name="image" content="../../img/banner/blog.webp" />
            </Helmet>
            <Banner
                imgUrl="../../img/banner/blog.webp"
                heading="Outsourced Bookkeeping Gallery."
                button='GET A VIRTUAL CONSULTATION'
            />
            {/* Our Attention To Detail */}
            {/* =================================== */}
            <section className="bg-light">
                <div className="container">
                    <h2 data-wow-delay="200ms">Check Our FAQ's</h2>
                    <div
                        className="accordion wow fadeInUp"
                        data-wow-delay="200ms"
                        id="accordionPanelsStayOpenExample"
                    >
                        {isLoading ? (
                            <ThreeDots />
                        ) : (
                            faqsData.map((data, index) => (
                                <div className="accordion-item mb-3" key={index}>
                                    <h2
                                        className="accordion-header mt-0 d-flex"
                                        id={`panelsStayOpen-heading-${index}`}
                                    >
                                        <button
                                            className="accordion-button"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#panelsStayOpen-collapse-${index}`}
                                            aria-expanded="true"
                                            aria-controls={`panelsStayOpen-collapse-${index}`}
                                        >
                                            <h4 className='text-main'>{data.title}</h4>
                                        </button>
                                    </h2>
                                    <div
                                        id={`panelsStayOpen-collapse-${index}`}
                                        className="accordion-collapse collapse show"
                                        aria-labelledby={`panelsStayOpen-heading-${index}`}
                                    >
                                        <div className="accordion-body">
                                            <div dangerouslySetInnerHTML={{__html: data.text}}/>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}

                    </div>
                </div>
            </section>
        </>
    );
}