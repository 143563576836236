import React, { useState } from 'react';

const GetQuoteForm = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [answers, setAnswers] = useState({});

    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };

    const handlePrevious = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleAnswerChange = (questionId, answer) => {
        setAnswers({ ...answers, [questionId]: answer });
    };

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return (
                    <Step1
                        onNext={handleNext}
                        onAnswerChange={(answer) => handleAnswerChange('question1', answer)}
                    />
                );
            case 2:
                return (
                    <Step2
                        onPrevious={handlePrevious}
                        onNext={handleNext}
                        onAnswerChange={(answer) => handleAnswerChange('question2', answer)}
                    />
                );
            case 3:
                return(
                    <Step3
                        onPrevious={handlePrevious}
                        onNext={handleNext}
                        onAnswerChange={(answer) => handleAnswerChange('question3', answer)}
                    />
                )
            default:
                return null;
        }
    };

    return (
        <div>
            <h1>Get Quote</h1>
            {renderStep()}
        </div>
    );
};

const Step1 = ({ onNext, onAnswerChange }) => {
    const handleNext = () => {
        onNext();
    };

    return (
        <section className='bg-light'>
            <div className='container' style={{ maxWidth: '800px' }}>
                <h2 className='text-center mb-4'>Get Quote in less than 60 seconds</h2>
                <div className="mt-3">
                    <h3>What is your business type? </h3>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="businessTypes" onChange={() => onAnswerChange('soleTrader')} id="soleTrader" defaultChecked />
                        <label className="form-check-label" htmlFor="soleTrader" defaultChecked>
                            Sole Trader
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="businessTypes" onChange={() => onAnswerChange('limitedCompany')} id="limitedCompany" />
                        <label className="form-check-label" htmlFor="limitedCompany">
                            Limited Company
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="businessTypes" onChange={() => onAnswerChange('partnership')} id="partnership" />
                        <label className="form-check-label" htmlFor="partnership">
                            Partnership
                        </label>
                    </div>
                    <div className='text-end'>
                        <button type="button" className="btn btn-secondary mt-2 text-end" onClick={handleNext}> Next
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

const Step2 = ({ onPrevious, onNext, onAnswerChange }) => {
    const handleNext = () => {
        // Perform validation if necessary
        onNext();
    };

    const handlePrevious = () => {
        onPrevious();
    };

    return (
        <section className='bg-light'>
            <div className='container' style={{ maxWidth: '800px' }}>
                <h2 className='text-center mb-4'>Get Quote in less than 60 seconds</h2>
                <div className="mt-3">
                    <h3>What is your annual income? </h3>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="annualIncome" onChange={() => onAnswerChange('85k')} id="85k" defaultChecked />
                        <label className="form-check-label" htmlFor="85k" defaultChecked>
                            Up to 85k
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="annualIncome" onChange={() => onAnswerChange('85To200k')} id="85To200k" />
                        <label className="form-check-label" htmlFor="85To200k">
                            85k-200k
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="annualIncome"  onChange={() => onAnswerChange('200kTo500k')} id="200kTo500k" />
                        <label className="form-check-label" htmlFor="200kTo500k">
                            200k-500k
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="annualIncome" onChange={() => onAnswerChange('500kTo1m')} id="500kTo1m" />
                        <label className="form-check-label" htmlFor="500kTo1m">
                            500k-1m
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="annualIncome" onChange={() => onAnswerChange('1m')} id="1m" />
                        <label className="form-check-label" htmlFor="1m">
                            1m+
                        </label>
                    </div>
                    <div>
                        <div className='row mt-4'>
                            <div className='col-6'>
                                <button type="button" className="btn btn-primary text-end" onClick={handlePrevious}> Previous</button>
                            </div>
                            <div className='col-6 text-end'>
                                <button type="button" className="btn btn-secondary text-end" onClick={handleNext}> Next
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
};
const Step3 = ({ onPrevious, onNext, onAnswerChange }) => {
    const handleNext = () => {
        // Perform validation if necessary
        onNext();
    };

    const handlePrevious = () => {
        onPrevious();
    };

    return (
        <section className='bg-light'>
            <div className='container' style={{ maxWidth: '800px' }}>
                <h2 className='text-center mb-4'>Get Quote in less than 60 seconds</h2>
                <div className="mt-3">
                    <h3>What services you are interested in?</h3>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" onChange={() => onAnswerChange('accounting')} id="accounting" />
                        <label className="form-check-label" htmlFor="accounting" defaultChecked>
                        Accounting
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" onChange={() => onAnswerChange('bookkeeping')} id="bookkeeping" />
                        <label className="form-check-label" htmlFor="bookkeeping">
                        Bookkeeping
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" onChange={() => onAnswerChange('vatReturns')} id="vatReturns" />
                        <label className="form-check-label" htmlFor="vatReturns">
                        VAT Returns
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" onChange={() => onAnswerChange('payroll')} id="payroll" />
                        <label className="form-check-label" htmlFor="payroll">
                        Payroll
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" onChange={() => onAnswerChange('pension')} id="pension" />
                        <label className="form-check-label" htmlFor="pension">
                        Pension
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" onChange={() => onAnswerChange('partnership')} id="partnership" />
                        <label className="form-check-label" htmlFor="partnership">
                        Pension
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" onChange={() => onAnswerChange('other')} id="partnership" />
                        <label className="form-check-label" htmlFor="other">
                        Other (Please specify)
                        </label>
                    </div>
                    <div>
                        <div className='row mt-4'>
                            <div className='col-6'>
                                <button type="button" className="btn btn-primary text-end" onClick={handlePrevious}> Previous</button>
                            </div>
                            <div className='col-6 text-end'>
                                <button type="button" className="btn btn-secondary text-end" onClick={handleNext}> Next
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
};

export default GetQuoteForm;