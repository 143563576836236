import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from 'react-router-dom';
import Banner from "../Banner";
import WOW from 'wowjs';
import { ThreeDots } from "react-loader-spinner";
import { Helmet } from "react-helmet";
const baseURL = "https://outsourcebookkeeping.us/api/get-blog-single";
const baseLocalURL = "http://localhost/projects/Outsourcedbookkeeping/eosa-api/get-blog-single";

export default function BlogSingle() {
    const [data, setBlogData] = useState(null);
    const [meta, setMetaData] = useState(null);
    const { slug } = useParams(); // Access slug from URL params
    useEffect(() => {
        const fetchBlogData = async () => {
            try {
                const response = await axios.post(baseLocalURL, { slug: slug });
                const data = response.data.blog;
                const metaData = response.data.blog_meta;
                setBlogData(data)
                setMetaData(metaData)

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchBlogData();
    }, []);

    let metaTitle;
    let metaDescription;
    let metaKeywords;
    if (meta) {
        const metaTitleSettings = meta.find(data => data.meta_key === 'meta_title');
        metaTitle = metaTitleSettings ? metaTitleSettings.meta_value : '';
        const metaDescriptionSettings = meta.find(data => data.meta_key === 'meta_description');
        metaDescription = metaDescriptionSettings ? metaDescriptionSettings.meta_value : '';
        const metaKeywordsSettings = meta.find(data => data.meta_key === 'meta_keywords');
        metaKeywords = metaKeywordsSettings ? metaKeywordsSettings.meta_value : '';
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        const wow = new WOW.WOW({ live: false });
        wow.init();
    }, []);

    console.log(meta)
    return (
        <>
            {meta ? (
                <Helmet>
                    <title>{data.title}</title>
                    <meta name="title" content={metaTitle} />
                    <meta name="description" content={metaDescription} />
                    <meta name="keywords" content={metaKeywords} />
                    <meta name="image" content="../../img/banner/banners-14.webp" />
                </Helmet>
            ) : null}

            {data ? (
                <>
                    <Banner imgUrl="../../img/banner/banners-14.webp" heading={data.title} button='GET A VIRTUAL CONSULTATION' />

                    <section
                        className="wow fadeInUp"
                        data-wow-delay="200ms"
                        style={{ paddingTop: "200px !important" }}
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <h2>{data.title} </h2>
                                    {/* Render the content as HTML */}
                                    <div dangerouslySetInnerHTML={{ __html: data.content }} />
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            ) : (
                <>
                    <Banner imgUrl="../../img/banner/banners-14.webp" heading="" button='GET A VIRTUAL CONSULTATION' />

                    <section>
                        <div className="container">
                            <ThreeDots />
                        </div>
                    </section>
                </>
            )}
        </>
    )
}
