import React from 'react'
import { Link } from 'react-router-dom'

export default function Banner(props) {
    return (
        <>
            <div className="row secondary_banner" style={{
                backgroundImage: `url(${props.imgUrl})`
            }}>
                <div className=''>
                    <div className="col-md-7">
                        <div
                            className="p-3 p-lg-5 text-white wow fadeInLeft"
                            data-wow-delay="200ms"
                            style={{ position: "relative", top: 75 }}
                        >
                            <div>
                                <h1 className="banner-heading">{props.heading}</h1>
                            </div>
                            {props.button ? (
                                <div className="mt-xl-5 mt-lg-0">
                                    <Link to="/contact-us"><button type="button" className="btn btn-secondary" style={{ padding: '12px 24px' }}>
                                        {props.button}
                                    </button></Link>
                                </div>
                            ) : (
                                <div></div> 
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
