import React, { useState, useEffect } from 'react';
import { swiperClientConfig, Swiper, SwiperClientSlide, swiperTestimonialsConfig, SwiperTestimonialsSlide } from "../MyCarousel";
import Banner from "../Banner";
import axios from 'axios';
import Swal from 'sweetalert2';
import $ from "jquery";
import ReCAPTCHA from 'react-google-recaptcha';
import WOW from 'wowjs';
import { ThreeDots } from 'react-loader-spinner';
import { Helmet } from "react-helmet";
import TestimonialSection from '../TestimonialSection';

var baseURL = 'https://outsourcebookkeeping.us/api/request-form-submit'
var baseLocalURL = 'http://localhost/projects/Outsourcedbookkeeping/eosa-api/request-form-submit'
var datanew = new FormData(($("#request-form")[0]))
function RequestQuote() {    
    const [isLoading, setIsLoading] = useState(false);
    const [inputValue, setInputValue] = useState({ businessType: "", annualIncome: "", transactions: "", otherServices: "", employeesNumber: "" });
    const [checkboxValues, setCheckboxValues] = useState([]);
    const [otherInput, setOtherInput] = useState(false)
    const [transactionsInput, setTransactionsInput] = useState(false)
    const [employeesInput, setEmployeesInput] = useState(false)
    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputValue((prevFormData) => ({ ...prevFormData, [name]: value }));
    };
    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setCheckboxValues((prevValues) => [...prevValues, value]);
        } else {
            setCheckboxValues((prevValues) => prevValues.filter((item) => item !== value));
        }
        if (value === 'Other') {
            handleOtherInput(value, checked);
        } else if ((value === 'Bookkeeping' || value === 'VAT Returns')) {
            handleTransactionsInput(value, checked)
        } else if ((value === 'Pension' || value === 'Payroll')) {
            handleEmployeesInput(value, checked)
        }
    };

    const handleOtherInput = (value, checked) => {
        if (checked && value === 'Other') {
            setOtherInput(true);
        } else {
            setOtherInput(false);
            setInputValue((prevInputValue) => ({ ...prevInputValue, otherServices: '' }));
        }
    }
    const handleTransactionsInput = (value, checked) => {
        if (checked && (value === 'Bookkeeping' || value === 'VAT Returns')) {
            setTransactionsInput(true);
        } else if (!checkboxValues.includes('Bookkeeping') || !checkboxValues.includes('VAT Returns')) {
            setTransactionsInput(false);
            setInputValue((prevInputValue) => ({ ...prevInputValue, transactionsServices: '' }));
        }
    }
    const handleEmployeesInput = (value, checked) => {
        if (checked && (value === 'Pension' || value === 'Payroll')) {
            setEmployeesInput(true);
        } else if (!checkboxValues.includes('Pension') || !checkboxValues.includes('Payroll')) {
            setEmployeesInput(false);
            setInputValue((prevInputValue) => ({ ...prevInputValue, employeesServices: '' }));
        }
    }
    useEffect(() => {
        console.log(inputValue);

        console.log(checkboxValues);
    }, [inputValue, checkboxValues]);

    const [step1Display, setStep1Display] = useState(false);
    const [step2Display, setStep2Display] = useState(true);
    const [step3Display, setStep3Display] = useState(true);
    const [step4Display, setStep4Display] = useState(true);
    const [step5Display, setStep5Display] = useState(true);
    const handleNextStep1 = () => {
        if (!inputValue.businessType || inputValue.businessType.trim() === "") {
            Swal.fire({
                icon: 'warning',
                title: 'Warning!',
                text: 'Please Select a Value'
            });
        } else {
            setStep1Display(true);
            setStep2Display(false);
        }
    }
    const handleNextStep2 = () => {
        if (!inputValue.annualIncome || inputValue.annualIncome.trim() === "") {
            Swal.fire({
                icon: 'warning',
                title: 'Warning!',
                text: 'Please Select a Value'
            });
        } else {
            setStep2Display(true)
            setStep3Display(false)
        }
    }

    const handleNextStep3 = () => {
        if (!checkboxValues || checkboxValues.length === 0) {
            Swal.fire({
                icon: 'warning',
                title: 'Warning!',
                text: 'Please Select atleast one Value'
            });
        } else if ((inputValue.otherServices === '' && otherInput === true) || (inputValue.employeesNumber === '' && employeesInput === true)) {
            Swal.fire({
                icon: 'warning',
                title: 'Warning!',
                text: 'Please fill all the inputs'
            });
        } else if ((inputValue.transactions === '' && transactionsInput === true)) {
            Swal.fire({
                icon: 'warning',
                title: 'Warning!',
                text: 'Please tell number of transactions you have per month'
            });
        } else {
            setStep3Display(true)
            setStep4Display(false)
        }
    }
    const handleNextStep4 = () => {
        setStep4Display(true)
        setStep5Display(false)
    }
    const handlePreviousStep1 = () => {
        setStep1Display(false)
        setStep2Display(true)
    }
    const handlePreviousStep2 = () => {
        setStep2Display(false)
        setStep3Display(true)
    }
    const handlePreviousStep3 = () => {
        setStep3Display(false)
        setStep4Display(true)
    }
    const handlePreviousStep4 = () => {
        setStep4Display(false)
        setStep5Display(true)
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        datanew = new FormData(($("#request-form")[0]))
        try {
            const response = await axios.post(baseLocalURL, datanew);
            setIsLoading(false)
            if (response.data.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: response.data.message
                });
            } else if (response.data.status === 103) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Warning',
                    text: response.data.message
                })
            } else if (response.data.status === 104) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: response.data.message
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: response.data.message
                });
            }
        } catch (error) {
            setIsLoading(false)
            console.error('Error submitting form:', error);
            alert('Failed to submit form');
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        const wow = new WOW.WOW({ live: false });
        wow.init();
    }, []);
    return (
        <>
        <Helmet>
            <title>Request a Quote</title>
            <meta name="title" content="Your meta Title" />
            <meta name="description" content="Your meta description" />
            <meta name="image" content="../../img/banner/banners-16.webp" />
        </Helmet>
            <Banner imgUrl="../../img/banner/banners-16.webp" heading="More than your typical Bookkeeping Firm." button='GET A VIRTUAL CONSULTATION' />
            <form id="request-form" onSubmit={handleSubmit} method='post' encType='multipart/form-data'>
                <section className={`bg-light ${step1Display ? "d-none" : ""}`} id='step1' >
                    <div className='container' style={{ maxWidth: '800px' }}>
                        <h2 className='text-center mb-4'>Get Quote in less than 60 seconds</h2>

                        <div className="mt-3">
                            <h3>What is your business type? </h3>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="businessType" value='Sole Trader' onChange={handleChange} id="soleTrader" />
                                <label className="form-check-label" htmlFor="soleTrader">
                                    Sole Trader
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="businessType" value='Limited Company' onChange={handleChange} id="limitedCompany" />
                                <label className="form-check-label" htmlFor="limitedCompany">
                                    Limited Company
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="businessType" value='Partnership' onChange={handleChange} id="partnership" />
                                <label className="form-check-label" htmlFor="partnership">
                                    Partnership
                                </label>
                            </div>
                            <div className='text-end'>
                                <button type="button" className="btn btn-secondary mt-2 text-end" onClick={handleNextStep1}> Next
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={`bg-light ${step2Display ? "d-none" : ""}`} id='annualIncome'>
                    <div className='container' style={{ maxWidth: '800px' }}>
                        <h2 className='text-center mb-4'>Get Quote in less than 60 seconds</h2>
                        <div className="mt-3">
                            <h3>What is your annual income? </h3>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="annualIncome" value='85K' onChange={handleChange} id="85k" />
                                <label className="form-check-label" htmlFor="85k" value='85K' onChange={handleChange}>
                                    Up to 85k
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="annualIncome" id="85To200k" value='85K to 200K' onChange={handleChange} />
                                <label className="form-check-label" htmlFor="85To200k">
                                    85k-200k
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="annualIncome" id="200kTo500k" value='200K to 500K' onChange={handleChange} />
                                <label className="form-check-label" htmlFor="200kTo500k">
                                    200k-500k
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="annualIncome" id="500kTo1m" value='500K to 1M' onChange={handleChange} />
                                <label className="form-check-label" htmlFor="500kTo1m">
                                    500k-1m
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="annualIncome" id="1m" value='1M' onChange={handleChange} />
                                <label className="form-check-label" htmlFor="1m">
                                    1m+
                                </label>
                            </div>
                            <div>
                                <div className='row mt-4'>
                                    <div className='col-6'>
                                        <button type="button" className="btn btn-primary text-end" onClick={handlePreviousStep1}> Previous</button>
                                    </div>
                                    <div className='col-6 text-end'>
                                        <button type="button" className="btn btn-secondary text-end" onClick={handleNextStep2}> Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
                <section className={`bg-light ${step3Display ? "d-none" : ""}`} id='step3'>
                    <div className='container' style={{ maxWidth: '800px' }}>
                        <h2 className='text-center mb-4'>Get Quote in less than 60 seconds</h2>
                        <div className="mt-3">
                            <h3>What services you are interested in?</h3>
                            <div className="form-check">
                                <input className="form-check-input" name='services[]' type="checkbox" value='Accounting' onChange={handleCheckboxChange} id="accounting" />
                                <label className="form-check-label" htmlFor="accounting" >
                                    Accounting
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" name='services[]' type="checkbox" value='Bookkeeping' onChange={handleCheckboxChange} id="bookkeeping" />
                                <label className="form-check-label" htmlFor="bookkeeping">
                                    Bookkeeping
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" name='services[]' type="checkbox" value='VAT Returns' onChange={handleCheckboxChange} id="vatReturns" />
                                <label className="form-check-label" htmlFor="vatReturns">
                                    VAT Returns
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" name='services[]' type="checkbox" value='Payroll' onChange={handleCheckboxChange} id="payroll" />
                                <label className="form-check-label" htmlFor="payroll">
                                    Payroll
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" name='services[]' type="checkbox" value='Pension' onChange={handleCheckboxChange} id="pension" />
                                <label className="form-check-label" htmlFor="pension">
                                    Pension
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" name='services[]' type="checkbox" value='Other' onChange={handleCheckboxChange} id="other" />
                                <label className="form-check-label" htmlFor="other">
                                    Other (Please specify)
                                </label>
                            </div>
                            <div className={`mb-3 ${otherInput ? "" : "d-none"}`}>
                                <h3>Describe the Service you want</h3>
                                <input
                                    type="text"
                                    className="form-control border border-success"
                                    placeholder="Services you need..."
                                    name='otherServices'
                                    onChange={handleChange}
                                />
                            </div>
                            <div className={`mb-3 ${employeesInput ? "" : "d-none"}`}>
                                <h3>How many employees do you have? </h3>
                                <input
                                    type="text"
                                    className="form-control border border-success"
                                    placeholder=""
                                    name='employeesNumber'
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <div className={`mb-3 ${transactionsInput ? "" : "d-none"}`}>
                                    <div className="mt-3">
                                        <h3>How many transactions do you have per month?</h3>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="transactions" value='Up to 20' onChange={handleChange} id="upto20" />
                                            <label className="form-check-label" htmlFor="upto20">
                                                Up to 20
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="transactions" value='20 to 40' onChange={handleChange} id="20to40" />
                                            <label className="form-check-label" htmlFor="20to40">
                                                20-40
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="transactions" value='40 to 80' onChange={handleChange} id="40to80" />
                                            <label className="form-check-label" htmlFor="40to80">
                                                40-80
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="transactions" value='80 to 120' onChange={handleChange} id="80to120" />
                                            <label className="form-check-label" htmlFor="80to120">
                                                80-120
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="transactions" value='120' onChange={handleChange} id="120" />
                                            <label className="form-check-label" htmlFor="120">
                                                120+
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='row mt-4'>
                                        <div className='col-6'>
                                            <button type="button" className="btn btn-primary text-end" onClick={handlePreviousStep2}> Previous</button>
                                        </div>
                                        <div className='col-6 text-end'>
                                            <button type="button" className="btn btn-secondary text-end" onClick={handleNextStep3}> Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >

                {/* Step 4 */}
                <section className={`bg-light ${step4Display ? "d-none" : ""}`} id='step5'>
                    <div className='container' style={{ maxWidth: '800px' }}>
                        <h2 className='text-center mb-4'>Get Quote in less than 60 seconds</h2>
                        <h3 className='text-center text-main mb-4'>Selected Fields from All Steps:</h3>
                        <div className="mt-3">
                            <h4>What services you are interested in?:</h4>{inputValue.annualIncome}
                            <h4>What is your annual income?</h4> {inputValue.annualIncome}
                            <h4>What is your business type?</h4> {checkboxValues.join(', ')}
                            {checkboxValues.includes('Pension') || checkboxValues.includes('Payroll') ? (
                                <div>
                                    <h4>Number of Employees working for you?</h4>
                                    <p>{inputValue.employeesNumber}</p>
                                </div>
                            ) : null}
                            {checkboxValues.includes('Bookkeeping') || checkboxValues.includes('VAT Returns') ? (
                                <div>
                                    <h4>Transactions per Month?</h4>
                                    <p>{inputValue.transactions}</p>
                                </div>
                            ) : null}
                            {checkboxValues.includes('Other') && <div>
                                <h4>Other Services</h4>
                                <p>{inputValue.otherServices}</p>
                            </div>}
                        </div>
                        <div className='row mt-4'>
                            <div className='col-md-6'>
                                <button type="button" className="btn btn-primary text-end" onClick={handlePreviousStep3}> Previous</button>
                            </div>
                            <div className='col-md-6 text-end'>
                                <button type="button" className="btn btn-secondary text-end" onClick={handleNextStep4}> Next
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Step 5 */}
                <section className={`bg-light ${step5Display ? "d-none" : ""}`} id='step4'>
                    <div className='container' style={{ maxWidth: '800px' }}>
                        <h2 className='text-center mb-4'>Get Quote in less than 60 seconds</h2>
                        <div>
                            <h3 className='text-center text-main mb-4'>Please fill your Details so that we can get back to you</h3>
                            <div className='container' style={{ maxWidth: '800px' }}>
                                <div className="mb-3">
                                    <input
                                        type="text"
                                        className="form-control border border-success"
                                        placeholder="Name"
                                        name='name'
                                        value={datanew.name}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <input
                                        type="text"
                                        className="form-control border border-success"
                                        placeholder="Email"
                                        name='email'
                                        value={datanew.email}
                                        required
                                    />
                                </div>
                                <div className='mb-3'>
                                    <input
                                        type="text"
                                        className="form-control border border-success"
                                        placeholder="Phone Number"
                                        name='phonenumber'
                                        value={datanew.phoneNumber}
                                        required
                                    />
                                </div>
                                <div className="pb-2">
                                    <ReCAPTCHA
                                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                                    />
                                </div>
                                {isLoading && (
                                    <ThreeDots
                                        visible={true}
                                        height="80"
                                        width="80"
                                        color="#008080"
                                        radius="9"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                    />
                                )}
                                <div>
                                    <div className='row mt-4'>
                                        <div className='col-6'>
                                            <button type="button" className="btn btn-primary text-end" onClick={handlePreviousStep4}> Previous</button>
                                        </div>
                                        <div className='col-6 text-end'>
                                            <button type="submit" className="btn btn-secondary text-end" > Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </form>
            {/* Engage the Bookkeeping Experts */}
            <TestimonialSection />
            {/* Carousel_one */}
            <section className="bg-light">
                <div className="ps-xl-5 pe-xl-5 wow fadeInLeft text-center" data-wow-delay="200ms">
                <Swiper {...swiperClientConfig}>
                        <SwiperClientSlide />
                    </Swiper>
                </div>
            </section>
        </>
    );

}


export default RequestQuote;