import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import { Swiper, SwiperClientSlide, swiperClientConfig, swiperServicesConfig, swiperServicesSlide } from "../MyCarousel";
import TestimonialSection from "../TestimonialSection";
// import $ from jQuery;
import Form from "../Form";
import WOW from 'wowjs';
import { Helmet } from "react-helmet";

export default function Home() {
    const [settingsData, setSettingsData] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0);
        const wow = new WOW.WOW({ live: false });
        wow.init();
    }, []);

    useEffect(() =>{
        const cachedData = localStorage.getItem('settingsData');
        if(cachedData){
            try{
                const parsedData = JSON.parse(cachedData);
                setSettingsData(parsedData);
            }catch(error){
                console.error('Error Parsing Data'. error);
                localStorage.removeItem('settingsData');
            }
        }
    },[])
    // Banner Heading  
    const headingDataSetting = settingsData.find(setting => setting.meta_key === 'setting_home_about_heading');
    const headingData = headingDataSetting ? headingDataSetting.meta_value : '';
    // Banner Content 
    const headingContentSetting = settingsData.find(setting => setting.meta_key === 'setting_home_about_heading');
    const headingContent = headingContentSetting ? headingContentSetting.meta_value : '';
    // Meta Tags 
    const metaTitleSetting = settingsData.find(setting => setting.meta_key === 'setting_home_meta_title');
    const metaTitle = metaTitleSetting ? metaTitleSetting.meta_value : ''; 

    const metaDescriptionSetting = settingsData.find(setting => setting.meta_key === 'setting_home_meta_description');
    const metaDescription = metaDescriptionSetting ? metaDescriptionSetting.meta_value : ''; 
    
    const metaKeywordsSetting = settingsData.find(setting => setting.meta_key === 'setting_home_meta_keywords');
    const metaKeywords = metaKeywordsSetting ? metaKeywordsSetting.meta_value : ''; 
    return (
        <>
            <Helmet>
                <title>Outsource Bookkeeping</title>

                {/* <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
                <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" /> */}
                <meta name="title" content={metaTitle} />
                <meta name="description" content={metaDescription} />
                <meta name="keywords" content={metaKeywords} />
                <meta name="image" content="img/banner/main-banners.webp" />
            </Helmet>
            <div className="row" id="main-banner" style={{
                backgroundImage:
                    "url(img/banner/main-banners.webp)"
            }}>
                <div
                    className="col-md-6 main_div_img_text d-flex" style={{ backgroundColor: "rgba(32, 201, 151, 0.9)" }}

                >

                    <div className="p-3 p-lg-5 pb-lg-0 text-white align-self-center">
                        <div>
                            <h1 className="banner-heading-main">{headingData}</h1>
                        </div>
                        <div className="mt-3">
                            <p>
                               {/* {headingContent} */}
                            </p>
                        </div>
                        <div className="mt-xl-5 mt-lg-0">
                            <Link to="/contact-us">
                                <button type="button" className="btn btn-dark">
                                    SCHEDULE A CONSULTATION
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div
                    className="p-5 sec_main_div_img_text col-md-12"
                    style={{ backgroundColor: "#20C997", opacity: "0.9" }}
                >
                    <div id="main_div_img_text_bottom">
                        <div className="p-4 pb-5 text-white">
                            <div>
                                <h1 className="banner-heading-main h1">Bookkeeping Services in San Antonio</h1>
                            </div>
                            <div className="mt-3">
                                <p>
                                    Your business is not built on numbers alone. A thoughtful and
                                    conscientious plan for your business brings growth and prosperity
                                    when applied with expertise. As a business owner in the San
                                    Antonio area, you know that keeping your books in order is
                                    essential to the success of your company. At Outsource Bookkeeping, we
                                    understand the importance of having reliable and professional
                                    bookkeepers on your side. With our expert team of professionals,
                                    we can take care of all your bookkeeping needs in a timely and
                                    efficient manner.
                                </p>
                            </div>
                            <div className="mt-xl-5 mt-lg-0">
                                <button type="button" className="btn btn-dark">
                                    SCHEDULE A CONSULTATION
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Carousel_one */}
            <section className="bg-light wow fadeInRight" data-wow-delay="600ms">
                <div className="ps-xl-5 pe-xl-5 text-center">
                    <Swiper {...swiperClientConfig}>
                        <SwiperClientSlide />
                    </Swiper>
                </div>
            </section>
            {/* What Does a Bookkeeper Do? */}
            <section>
                <div className="container">
                    <div className="row text-center wow fadeInUp" data-wow-delay="200ms">
                        <div className="col">
                            <h2>What Does a Bookkeeper Do?</h2>
                            <p>
                                Our team has years of experience working with businesses of all
                                sizes, and we pride ourselves on our ability to provide quality
                                service that helps you keep your finances organized and grow your
                                business. Whether you need ongoing bookkeeping support or just
                                occasional assistance with specific tasks, we have the expertise and
                                tools necessary to get the job done right.
                            </p>
                            <p>
                                A mature business relies on smart strategies, planning in advance,
                                and attention to detail to fulfill its mission’s full potential. A
                                business’s success comes down to how it maneuvers its cash flow from
                                the top, down — and most importantly — the good folks who design and
                                execute that strategy. Welcome to OutsourceBookkeeping. We are here for
                                you.
                            </p>
                            <div className="mt-4">
                                <Link to="/services"><button type="button" className="btn btn-main">
                                    SEE OUR SERVICES
                                </button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Carousel_sec */}
            <section className="bg-light">
                <div className="container text-center wow fadeIn" data-wow-delay="600ms">
                    <h2 className="pb-4" data-wow-delay="200ms">Our Services</h2>
                    <div id="Carousel_sec" className="owl-carousel owl-theme">
                        <Swiper {...swiperServicesConfig}>
                            {swiperServicesSlide}
                        </Swiper>
                    </div>
                </div>
            </section>
            <div className="bg-secondary p-2 wow fadeInLeft" data-wow-delay="200ms">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-md-6 pt-1">
                            <img
                                src="img/logo/white-logo.png"
                                width="100%"
                                height="50px"
                            />
                        </div>
                        <div className="col-xl-6 col-md-6 pt-3 text-white h5 text-center align-self-center">
                            <p>We are a Bookkeeper for QuickBooks® software in San Antonio</p>
                        </div>
                        <div className="col-xl-3 pt-2 pb-2 text-center align-self-center">
                            <Link to="/contact-us">
                                <button type="button" className="btn btn-main">
                                    REQUEST AN ESTIMATE
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* Outsourcing Bookkeeping Services */}
            {/* =================================== */}
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 wow fadeIn" data-wow-delay="600ms">
                            <h2>Outsourcing Bookkeeping Services</h2>
                            <p>
                                Outsource Bookkeeping is San Antonio’s Leader in unparalleled customer
                                service, expert knowledge, clear reporting, small business
                                bookkeeping, and business advisory.
                            </p>
                            <p>
                                <span className="text-main">OutsourceBookkeeping’s staff</span> is
                                well-versed with the complexities of bookkeeping — and we are the
                                top choice for business owners when it comes to unbeatable, friendly
                                service and attentive staff. We specialize in fixing current issues,
                                planning for the future, and removing surprises in all areas in your
                                financial operations.
                            </p>
                            <p>
                                {" "}
                                OutsourceBookkeeping℠ is led by industry veteran,{" "}
                                <span className="text-main">Charlie Perrin </span>, in San Antonio,
                                Texas. We take pride in being your business mentors and neighborly
                                guides. We are a trusted business partner, but we are also
                                insightful advisors — Providing solutions to help your business
                                grow.{" "}
                            </p>
                            <Link to="/contact-us">
                                <button type="button" className="btn-main">
                                    GET STARTED
                                </button>
                            </Link>
                        </div>
                        <div className="col-xl-6 pt-5 wow fadeIn" data-wow-delay="800ms">
                            <img
                                src="img/images/AdobeStock_499927665-1024x620.jpeg"
                                width="100%"
                                height=" 100%"
                                className="rounded"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <div
                className="container-md container-fluid-sm"
                style={{ position: "relative", top: 60 }}
            >
                <div
                    className="row p-4 rounded text-white wow fadeIn"
                    data-wow-delay="600ms"
                    style={{ backgroundColor: "#20C997" }}
                >
                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="d-flex countdown-icons pb-4 pb-sm-4 pb-lg-0">
                            <img src="img/icon/united.png" alt="" />
                            <div className="ps-2 pt-3 align-self-center">
                                <span className="h3"> 16+ </span>
                                <p> Team Members </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="d-flex countdown-icons pb-4 pb-sm-4 pb-lg-0">
                            <img src="img/icon/client.png" alt="" />
                            <div className="ps-2 pt-3 align-self-center">
                                <span className="h3">300+</span>
                                <p> Clients Served Monthly </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="d-flex countdown-icons pb-4 pb-sm-4 pb-lg-0">
                            <img src="img/icon/annual-calendar.png" alt="" />
                            <div className="ps-3 pt-3 align-self-center">
                                <span className="h3">3,500+</span>
                                <p>Annual Period Closing</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="d-flex countdown-icons pb-4 pb-sm-4 pb-lg-0">
                            <img src="img/icon/hour-glass.png" alt="" />
                            <div className="ps-2 pt-3 align-self-center">
                                <span className="h3">25,000+</span>
                                <p>Hours saved on Bookkeeping</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Investing In Client Relationships */}
            <section className="bg-secondary pb-1 pt-3">
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div
                            className="col-lg-6 wow fadeInUp text-white"
                            data-wow-delay="200ms"
                        >
                            <div
                                className="pe-4 text-center"
                                id="Investing_In_Client_Relationships"
                            >
                                <h2 className="mt-5 text-black" >
                                    Investing In Client Relationships to Reinforce Your Managed
                                    Bookkeeping Services.
                                </h2>
                                <p>
                                    xperienced guidance, and seamless client relationships; we don’t
                                    just handle your bookkeeping needs, we get it done with
                                    unparalleled agility and consistent correspondence with you and
                                    your team. We are your local, approachable bookkeeping team.
                                </p>
                                <Link to="/about">
                                    <button type="button" className="btn btn-main mt-2">
                                        READ ABOUT US
                                    </button>
                                </Link>
                            </div>
                        </div>
                        {/* Form Component */}
                        <Form />
                    </div>
                </div>
            </section>
            {/* Why Consider Outsource Bookkeeping Services */}
            <section>
                <div className="container">
                    <div className="row">
                        <div
                            className="col-md-8 col-xl-4 text-center wow fadeInLeft"
                            data-wow-delay="600ms"
                        >
                            <img
                                className="why_choose_us_img"
                                src="img/images/Business-Owner-Why-Consider.jpg"
                                alt="...."
                                width="80%"
                            />
                        </div>
                        <div
                            className="col-md-12 col-xl-8 col-sm-12 p-5 bg-light rounded wow fadeInRight"
                            data-wow-delay="200ms"
                        >
                            <h2>Why Consider Outsource Bookkeeping Services ?</h2>
                            <h5 className="text-main">
                                It is vital to hire efficient bookkeeping services to ensure the
                                smooth, on-going progress of your organization. We have asked, and
                                users who did not have a professional set up their books initially,
                                universally regretted it.
                            </h5>
                            <p>
                                OutsourceBookkeeping℠ offers premier consulting for bookkeeping services
                                for leading businesses in San Antonio. A Member of the QuickBooks
                                ProAdvisor Program is a professional that has been trained and
                                certified on how to best leverage QuickBooks software. We do not
                                only have the know-how, but we also deeply understand how your
                                business runs and we are here to help you solve the problems that
                                can arise, with no stress and no tricky language. Give us a call to
                                find out just how simple it is to have our welcoming staff get you
                                started.
                            </p>
                            <Link to="/contact-us">
                                <button type="button" className="btn btn-main mb-2">
                                    START A CONVERSATION
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* Testimonial Section */}
            <TestimonialSection />
            {/* 9 Signs Your Business Needs */}
            <section>
                <div className="container">
                    <h2 data-wow-delay="200ms">What Does a Bookkeeper Do?</h2>
                    <div className="row wow fadeInUp" data-wow-delay="300ms">
                        <div className="col-lg-4 mt-3">
                            <div className="card blog-card">
                                <img
                                    id=""
                                    src="./img/images/bookkeeping-1024x682.jpg"
                                    alt="...."
                                    width=""
                                />
                                <div className="p-4 card-body">
                                    <p className="blog-date card-text text-main">March 15, 2024</p>
                                    <h3 className="card-title">
                                        9 Signs Your Business Needs to Invest in Outsourced Bookkeeping
                                    </h3>
                                    <p className="card-text">
                                        Not being able to cover your business expenses at the end of the
                                        month is a terrible feeling. Despite the busy month you had,
                                        your books…
                                    </p>
                                </div>
                                <div className="d-flex p-4 fw-bold border-top px-1-6 px-sm-1-9 py-3 border-color-light-black justify-content-between">
                                    <Link
                                        to="/blog-single"
                                        className="link-underline  link-underline-opacity-0"
                                    >
                                        READ MORE
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-3">
                            <div className="card blog-card">
                                <img
                                    id=""
                                    src="img/images/outsourced-payroll-providers-1024-tiny.png"
                                    alt="...."
                                    width=""
                                />
                                <div className="p-4 card-body">
                                    <p className="blog-date card-text text-main">January 29, 2024</p>
                                    <h3 className="card-title">
                                        9 Qualities to Look for in Outsourced Payroll Providers
                                    </h3>
                                    <p className="card-text">
                                        It only takes one shortcoming to derail a company’s progress.
                                        This often includes market research or employee morale, but it
                                        can also involve how you manage your…
                                    </p>
                                </div>
                                <div className="d-flex p-4 fw-bold border-top px-1-6 px-sm-1-9 py-3 border-color-light-black justify-content-between">
                                    <Link
                                        to="/blog-single"
                                        className="link-underline  link-underline-opacity-0"
                                    >
                                        READ MORE
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-3">
                            <div className="card blog-card">
                                <img
                                    id=""
                                    src="img/images/AdobeStock_461500216-1024-tiny.jpeg"
                                    alt="...."
                                    width=""
                                />
                                <div className="p-4 card-body">
                                    <p className="blog-date card-text text-main">December 15, 2023</p>
                                    <h3 className="card-title">
                                        9 Reasons to Invest in Outsourced Bookkeeping Services
                                    </h3>
                                    <p className="card-text">
                                        Imagine having the ability to work when and where you want. For
                                        many business owners, this is a genuine possibility. However,
                                        this doesn’t come without a…
                                    </p>
                                </div>
                                <div className="d-flex p-4 fw-bold border-top px-1-6 px-sm-1-9 py-3 border-color-light-black justify-content-between">
                                    <Link
                                        to="/blog-single"
                                        className="link-underline  link-underline-opacity-0"
                                    >
                                        READ MORE
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
