import React, { useEffect } from "react";
import { Link } from 'react-router-dom'
import { swiperClientConfig, Swiper, SwiperClientSlide } from "../../MyCarousel";
import Banner from "../../Banner";
import Form from "../../Form";
import WOW from 'wowjs';
import { Helmet } from "react-helmet";

export default function IndustriesWeServe() {
    useEffect(() => {
        window.scrollTo(0, 0);
        const wow = new WOW.WOW({ live: false });
        wow.init();
    }, []);

    return (
        <>
            <Helmet>
                <title>Payroll Services</title>
                <meta name="title" content="Your meta Title" />
                <meta name="description" content="Your meta description" />
                <meta name="image" content="../../img/banner/banners-9.webp" />
            </Helmet>
            <Banner imgUrl="../../img/banner/banners-9.webp" heading="Payroll Services" button='GET A VIRTUAL CONSULTATION' />
            {/* Our Attention To Detail */}
            {/* =================================== */}
            <section className="pb-0" style={{ paddingTop: "200px !important" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 wow fadeIn" data-wow-delay="600ms">
                            <h3>
                                A lot of businesses struggle with bookkeeping, and it can be a
                                daunting task. But don't worry, we've got you covered.
                            </h3>
                            <p>
                                {" "}
                                We provide you with a dedicated bookkeeping team led by an
                                experienced account manager who manages your automatic transaction
                                import, tracks income and expenses, and prepares and manages your
                                professional bookkeeping reports with supporting documents. In
                                addition, we will build a monthly set of supporting work papers,
                                record and account for all income and expenses, reconcile your bank
                                and credit card accounts, and produce management reports that let
                                you know where your business stands. From basic bookkeeping to
                                advisory services Outsource Bookkeeping has you covered.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* Why Consider Outsource Bookkeeping Services */}
            <section>
                <div className="container">
                    <div className="row bg-light">
                        <div
                            className="col-md-8 col-lg-4 text-center wow fadeInLeft"
                            data-wow-delay="600ms"
                        >
                            <img
                                className="why_choose_us_img"
                                src="img/images/Business-Owner-Why-Consider.jpg"
                                alt="...."
                                width="80%"
                            />
                        </div>
                        <div
                            className="col-md-12 col-lg-8 col-sm-12 p-5 rounded wow fadeInRight"
                            data-wow-delay="200ms"
                        >
                            <h3>Why choose Outsource Bookkeeping?</h3>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sequi praesentium quis laborum aliquid quas. Corrupti obcaecati vitae ad neque hic maiores veniam dolore illum quam officia sint laborum unde, delectus aliquam exercitationem omnis dolorum, provident dicta voluptas molestiae impedit? A perferendis atque, nostrum expedita dolores, officia nisi voluptatibus excepturi numquam rerum molestias ratione ad distinctio corporis vero. Excepturi vitae labore qui aliquam autem exercitationem odit voluptas enim doloremque velit, laborum distinctio iusto tempora dicta aperiam!
                            </p>
                            <Link to="/contact-us">
                                <button type="button" className="btn btn-main">
                                    START A CONVERSATION
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* Our Attention To Detail */}
            {/* =================================== */}
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 wow fadeIn pe-5" data-wow-delay="600ms">
                            <h3>
                                Our Attention To Detail And Client-Focused Staff Are What Set Us
                                Apart. Let Outsource Bookkeeping Help You Streamline Your Books So You
                                Can Get Back To Business.
                            </h3>
                            <p>
                                {" "}
                                We provide a wide range of bookkeeping and software solutions to
                                amplify your business. Whether you are a mature organization seeking
                                to free up time spent on books or a large company that needs to
                                track finances to prepare for investors and other outside capital,
                                the team at Outsource Bookkeeping can provide the outstanding service
                                that you need to continue moving forward.
                            </p>
                            <Link to="/contact-us">
                                <button type="button" className="btn-main">
                                    GET STARTED
                                </button>
                            </Link>
                        </div>
                        <div className="col-md-6 pt-5 p-lg-1 wow fadeIn" data-wow-delay="800ms">
                            <img
                                src="img/images/services1.jpeg"
                                className="rounded-top rounded-bottom"
                                width="100%"
                                height=" 100%"
                            />
                        </div>
                    </div>
                </div>
            </section>
            {/* Our Attention To Detail */}
            {/* =================================== */}
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 pt-5 p-lg-1 wow fadeIn" data-wow-delay="800ms">
                            <img
                                src="img/images/services1.jpeg"
                                className="rounded-top rounded-bottom"
                                width="100%"
                                height=" 100%"
                            />
                        </div>
                        <div className="col-md-6 wow fadeIn ps-5" data-wow-delay="600ms">
                            <h3>
                                Our Attention To Detail And Client-Focused Staff Are What Set Us
                                Apart. Let Outsource Bookkeeping Help You Streamline Your Books So You
                                Can Get Back To Business.
                            </h3>
                            <p>
                                {" "}
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis ut deleniti vel iusto, aliquam laudantium eligendi incidunt cumque perferendis voluptatum placeat accusantium quos, ad soluta esse temporibus, fuga eos iure vitae nemo. Laudantium perferendis neque, labore accusamus quod pariatur minima cum molestias excepturi magni culpa molestiae repellendus assumenda quia blanditiis optio dicta suscipit debitis mollitia veritatis? Tempora, minus! Voluptatum perspiciatis odit facilis.
                            </p>
                            <Link to="/contact-us">
                                <button type="button" className="btn-main">
                                    GET STARTED
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* Investing In Client Relationships */}
            <section className="bg-secondary pb-1 pt-3">
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div
                            className="col-lg-6 wow fadeInUp text-white"
                            data-wow-delay="200ms"
                        >
                            <div
                                className="pe-4 text-center"
                                id="Investing_In_Client_Relationships"
                            >
                                <h2 className="text-main mt-5">
                                    Investing In Client Relationships to Reinforce Your Managed
                                    Bookkeeping Services.
                                </h2>
                                <p>
                                    xperienced guidance, and seamless client relationships; we don’t
                                    just handle your bookkeeping needs, we get it done with
                                    unparalleled agility and consistent correspondence with you and
                                    your team. We are your local, approachable bookkeeping team.
                                </p>
                                <Link to="/about">
                                    <button type="button" className="btn btn-main mt-2">
                                        READ ABOUT US
                                    </button>
                                </Link>
                            </div>
                        </div>
                        {/* Form Component */}
                        <Form />
                    </div>
                </div>
            </section>
            {/* Carousel_one */}
            <section className="text-center">
                <div className="ps-xl-5 pe-xl-5 wow fadeInLeft" data-wow-delay="200ms">
                <Swiper {...swiperClientConfig}>
                        <SwiperClientSlide />
                    </Swiper>
                </div>
            </section>

            <div className="bg-secondary p-2 wow fadeInLeft" data-wow-delay="200ms">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-md-6 pt-1">
                            <img
                                src="img/logo/white-logo.png"
                                width="100%"
                                height="50px"
                            />
                        </div>
                        <div className="col-xl-6 col-md-6 pt-3 text-white h5 text-center align-self-center">
                            <p>We are a Bookkeeper for QuickBooks® software in San Antonio</p>
                        </div>
                        <div className="col-xl-3 pt-2 pb-2 text-center align-self-center">
                            <Link to="/contact-us">
                                <button type="button" className="btn btn-main">
                                    REQUEST AN ESTIMATE
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* Engage the Bookkeeping Experts */}
            <section className="bg-dark">
                <div className="container">
                    <div className="row">
                        <div
                            className="col-lg-12 text-white wow fadeIn"
                            data-wow-delay="600ms"
                            style={{ padding: "60px 46px" }}
                        >
                            <h2 className="main-head">Next Steps </h2>
                            <p>
                                If you’re feeling overwhelmed by bookkeeping and finances, don’t
                                worry. You can take the hassle out of managing your bookkeeping
                                process by hiring a professional to do it for you. Professional
                                bookkeepers can help you stay on top of your expenses, budget, and
                                taxes so that you can focus on what you’re good at – running your
                                business. Contact us today to get started!
                            </p>
                            <Link to="/contact-us">
                                <button type="button" className="btn btn-main">
                                    CONTACT US
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}