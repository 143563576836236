import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faFacebookF, faLinkedinIn, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
export default function Footer() {
    const [settingsData, setSettingsData] = useState([]);

    useEffect(() => {
        const cachedData = localStorage.getItem('settingsData');
        if (cachedData) {
            try {
                const parsedData = JSON.parse(cachedData);
                setSettingsData(parsedData);
            } catch (error) {
                console.error('Error parsing cached data:', error);
                localStorage.removeItem('settingsData');
            }
        }
    }, []);
    //Logo URL
    const footerLogoSetting = settingsData.find(setting => setting.meta_key === 'setting_footer_logo');
    const footerLogoUrl = footerLogoSetting ? footerLogoSetting.meta_value : '';

    // Phone Number 
    const phoneSetting = settingsData.find(setting => setting.meta_key === 'setting_phone');
    const phoneNumber = phoneSetting ? phoneSetting.meta_value : '';

    // E-mail 
    const emailSetting = settingsData.find(setting => setting.meta_key === 'setting_email');
    const emailNumber = emailSetting ? emailSetting.meta_value : '';

    // Location
    const addressSetting = settingsData.find(setting => setting.meta_key === 'setting_contact_location');
    const addressNumber = addressSetting ? addressSetting.meta_value : '';

    //Twitter Link
    const twitterSetting = settingsData.find(setting => setting.meta_key === 'setting_twitter_link');
    const twitterLink = twitterSetting ? twitterSetting.meta_value : '';

    //Instagram Link
    // const twitterSetting = settingsData.find(setting => setting.meta_key === 'setting_instagram_link');
    // const twitterLink = twitterSetting ? twitterSetting.meta_value : ''; 

    //Facebook Link
    const facebookSetting = settingsData.find(setting => setting.meta_key === 'setting_facebook_link');
    const facebookLink = facebookSetting ? facebookSetting.meta_value : '';

    //LinkedIn Link
    const linkedInSetting = settingsData.find(setting => setting.meta_key === 'setting_linkedin_link');
    const linkedInLink = linkedInSetting ? linkedInSetting.meta_value : '';

    //Youtube Link
    const youtubeSetting = settingsData.find(setting => setting.meta_key === 'setting_youtube_link');
    const youtubeLink = youtubeSetting ? youtubeSetting.meta_value : '';
    const currentYear = new Date().getFullYear();
    return (
        <>
            <footer
                className="bg-secondary wow fadeIn"
                data-wow-delay="200ms"
                style={{ padding: "50px 0px 30px" }}
            >
                <div className="row ms-4 me-4 bg-main rounded">
                    <div className="col-md-12 p-0">
                        <div className="row bg-white m-3 p-3 rounded">
                            <div className="col-lg-3">
                                <img
                                    className="pb-3"
                                    src={process.env.REACT_APP_IMG_PATH+"uploads/"+footerLogoUrl}
                                    width="80%"
                                    height="70px"
                                />
                                <div className="h6">
                                    {" "}
                                    © Copyright {currentYear} Global Bookkeeping . All rights reserved.
                                </div>
                                <div className="h6">
                                    {" "}
                                    We are Headquartered in In San Antonio, Texas.
                                </div>
                                {/* <div>
                                    <ul className="nav">
                                        <li className="nav-item "> Privacy Policy</li>
                                        <li className="nav-item ps-3"> HTML Sitema XML </li>
                                        <li className="nav-item ps-3"> Sitemap </li>
                                    </ul>
                                </div> */}
                            </div>


                            <div className="col-lg-3">
                                <h3>About Us</h3>
                                <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam sit possimus dolorum exercitationem laboriosam voluptatum suscipit odio voluptates eius laudantium blanditiis rem molestias quas, quis, obcaecati eligendi, quo dolor non. Odio, voluptatem eligendi optio libero, natus modi sapiente praesentium rerum, quo maxime quasi vero eum! Quos a nulla possimus corrupti cupiditate iure nam omnis. </div>
                            </div>
                            <div className="col-lg-3 footer-services d-lg-flex align-items-center flex-column">
                                <ul>
                                <h3>Services Links</h3>
                                    <li>
                                        <a href="/accounting-services">Accounting Services</a>
                                    </li>
                                    <li>
                                        <a href="/accounts-payable-services">Accounts Payable Services</a>
                                    </li>
                                    <li>
                                        <a href="/accounts-receivable-services">Accounts Receivable Services</a>
                                    </li>
                                    <li>
                                        <a href="/bookkeeping-services">Bookkeeping Services</a>
                                    </li>
                                    <li>
                                        <a href="/data-conversion-services">Data Conversion Services</a>
                                    </li>
                                    <li>
                                        <a href="/payroll-services">Payroll Services</a>
                                    </li>
                                    <li>
                                        <a href="/services-for-cpa-ea">Services for CPA and EA</a>
                                    </li>
                                    <li>
                                        <a href="/tax-preparation-services">Tax Preparation Services</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-3 social-links">
                                <h3>Follow Us</h3>
                                <div className="pt-2 pb-2"> {phoneNumber}</div>
                                <div className="pt-2 pb-2"> {emailNumber}</div>
                                <div className="pt-2 pb-2"> {addressNumber}</div>
                                {" "}
                                <div className="pt-2 pb-2">
                                    <a href={facebookLink}>
                                        <FontAwesomeIcon icon={faFacebookF} />
                                    </a>
                                    <a href={linkedInLink}>
                                        {" "}
                                        <FontAwesomeIcon icon={faLinkedinIn} />
                                    </a>
                                    <a href={twitterLink}>
                                        {" "}
                                        <FontAwesomeIcon icon={faXTwitter} />
                                    </a>
                                    <a href="">
                                        {" "}
                                        <FontAwesomeIcon icon={faInstagram} />
                                    </a>
                                    <a href={youtubeLink}>
                                        {" "}
                                        <FontAwesomeIcon icon={faYoutube} />
                                    </a>
                                </div>
                                <div className="pt-2 pb-2">Powered by <a target='_blank' rel='noopener noreferrer' href="https://www.uvsoftsolutions.com/" >UV Soft Solutions</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div
                id="fixed_contact_us"
                className="bg-main container-fluid"
                style={{ position: "fixed", bottom: 0, zIndex: 2, padding:15 }}
            >
                <div className="d-flex justify-content-between">
                    <div className="align-self-center">
                        <p className="h5 m-0 text-white">
                            <img src="../img/icon/phone-call.png" alt="" /> {phoneNumber}{" "}
                        </p>
                    </div>
                    <div className="">
                        <button type="button" className="btn btn-secondary">
                            CONTACT US
                        </button>
                    </div>
                </div>
            </div>
        </>

    )
}