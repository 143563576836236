import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import { Swiper, swiperClientConfig, SwiperClientSlide, swiperServicesConfig, swiperServicesSlide } from "../MyCarousel";
import Banner from "../Banner";
import TestimonialSection from "../TestimonialSection";
import Form from "../Form";
import WOW from 'wowjs';
import { Helmet } from "react-helmet";

export default function About() {
    const [settingsData, setSettingsData] = useState([]);

    useEffect(() => {
        const cachedData = localStorage.getItem('settingsData')
        if (cachedData) {
            try {
                const parseData = JSON.parse(cachedData);
                setSettingsData(parseData);
            } catch(error) {
                console.error("Error Parsing Data", error);
                localStorage.removeItem('settingsData');
            }
        }
    })
    useEffect(() => {
        window.scrollTo(0, 0);
        const wow = new WOW.WOW({ live: false });
        wow.init();
    }, []);

    // Page Title 
    const titleSettings = settingsData.find(settings => settings.meta_key === 'setting_aboutus_title');
    const pageTitle = titleSettings ? titleSettings.meta_value : '';
    // Page Banner 
    const bannerSettings = settingsData.find(settings => settings.meta_key === 'setting_aboutus_title_banner');
    const pageBanner = bannerSettings ? bannerSettings.meta_value : '';

    // Meta Tags 
    const metaTitleSetting = settingsData.find(setting => setting.meta_key === 'setting_aboutus_meta_title');
    const metaTitle = metaTitleSetting ? metaTitleSetting.meta_value : ''; 

    const metaDescriptionSetting = settingsData.find(setting => setting.meta_key === 'setting_aboutus_meta_description');
    const metaDescription = metaDescriptionSetting ? metaDescriptionSetting.meta_value : ''; 
    
    const metaKeywordsSetting = settingsData.find(setting => setting.meta_key === 'setting_aboutus_meta_keywords');
    const metaKeywords = metaKeywordsSetting ? metaKeywordsSetting.meta_value : ''; 
    return (
        <>
            <Helmet>
                <title>About Us</title>
                <meta name="title" content={metaTitle} />
                <meta name="description" content={metaDescription} />
                <meta name="keywords" content={metaKeywords} />
                <meta name="image" content={process.env.REACT_APP_IMG_PATH+"uploads/"+pageBanner} />
            </Helmet>
            <Banner imgUrl={process.env.REACT_APP_IMG_PATH+"uploads/"+pageBanner} heading={pageTitle} button='GET A VIRTUAL CONSULTATION' />
            {/* What Does a Bookkeeper Do? */}
            <section
                className="wow fadeInUp"
                data-wow-delay="200ms"
                style={{ paddingTop: "200px !important" }}
            >
                <div className="container">
                    <div className="row text-center">
                        <div className="col">
                            <h2>What Does a Bookkeeper Do?</h2>
                            <p>
                                Our team has years of experience working with businesses of all
                                sizes, and we pride ourselves on our ability to provide quality
                                service that helps you keep your finances organized and grow your
                                business. Whether you need ongoing bookkeeping support or just
                                occasional assistance with specific tasks, we have the expertise and
                                tools necessary to get the job done right.
                            </p>
                            <p>
                                A mature business relies on smart strategies, planning in advance,
                                and attention to detail to fulfill its mission’s full potential. A
                                business’s success comes down to how it maneuvers its cash flow from
                                the top, down — and most importantly — the good folks who design and
                                execute that strategy. Welcome to OutsourceBookkeeping. We are here for
                                you.
                            </p>
                            <div className="mt-4">
                                <Link to="/services">
                                    <button type="button" className="btn btn-main">
                                        SEE OUR SERVICES
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="bg-secondary p-2 wow fadeInLeft" data-wow-delay="200ms">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-md-6 pt-1">
                            <img
                                src="img/logo/white-logo.png"
                                width="100%"
                                height="50px"
                            />
                        </div>
                        <div className="col-xl-6 col-md-6 pt-3 text-white h5 text-center align-self-center">
                            <p>We are a Bookkeeper for QuickBooks® software in San Antonio</p>
                        </div>
                        <div className="col-xl-3 pt-2 pb-2 text-center align-self-center">
                            <Link to="/contact-us">
                                <button type="button" className="btn btn-main">
                                    REQUEST AN ESTIMATE
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <section className="bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 wow fadeInRight" data-wow-delay="200ms">
                            <div className="card mb-3 about-card">
                                <div className="card-body">
                                    <h5 className="card-title">Professionalism</h5>
                                    <p className="card-text">
                                        We are San Antonio’s leading bookkeeping firm. By combining our
                                        bookkeeping mastery, entrepreneurial experience, and positive
                                        energy, each of our clients receives personal and professional
                                        attention from our service-focused staff. Our personal high
                                        standards and specialized staff create the difference between
                                        our outstanding performance and other firms’.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 wow fadeInLeft" data-wow-delay="200ms">
                            <div className="card mb-3 about-card">
                                <div className="card-body">
                                    <h5 className="card-title">Quality Bookkeeper Services</h5>
                                    <p className="card-text">
                                        As a bookkeeping firm that is known for the quality of our
                                        service, our firm’s reputation reflects the high standards we
                                        demand of ourselves. Our primary goal, as your trusted advisor,
                                        is to be available and to provide insightful advice that enables
                                        our clients to make informed financial decisions. We do not
                                        accept anything less from ourselves and this is what we deliver
                                        to you.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 wow fadeInRight" data-wow-delay="200ms">
                            <div className="card mb-3 about-card">
                                <div className="card-body">
                                    <h5 className="card-title">Responsiveness</h5>
                                    <p className="card-text">
                                        We are here for you. We will answer your call and greet you as a
                                        partner — not just a client. We always keep your business’
                                        growth and welfare as our first priority. Our quick
                                        communication has earned us the respect of our clients and our
                                        community. Our clients are quick to point out our diverse
                                        talents, dedication, agility, and flexibility.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Investing In Client Relationships */}
            <section className="bg-secondary pb-1 pt-3">
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div
                            className="col-lg-6 wow fadeInUp text-white"
                            data-wow-delay="200ms"
                        >
                            <div
                                className="pe-4 text-center"
                                id="Investing_In_Client_Relationships"
                            >
                                <h2 className="text-main mt-5">
                                    Investing In Client Relationships to Reinforce Your Managed
                                    Bookkeeping Services.
                                </h2>
                                <p>
                                    xperienced guidance, and seamless client relationships; we don’t
                                    just handle your bookkeeping needs, we get it done with
                                    unparalleled agility and consistent correspondence with you and
                                    your team. We are your local, approachable bookkeeping team.
                                </p>
                                <Link to="/about">
                                    <button type="button" className="btn btn-main mt-2">
                                        READ ABOUT US
                                    </button>
                                </Link>
                            </div>
                        </div>
                        {/* Form Component */}
                        <Form />
                    </div>
                </div>
            </section>
            {/* Carousel_sec */}
            <section className="wow fadeIn" data-wow-delay="800ms">
                <div className="container text-center">
                    <h3 className="h1 pb-3">We Help Businesses Stay Financially On-Track</h3>
                    <p className="pb-5">
                        See our Services page for more information about how we can help your
                        business thrive.
                    </p>
                    <div id="Carousel_sec" className="owl-carousel owl-theme">
                        <Swiper {...swiperServicesConfig}>
                            {swiperServicesSlide}
                        </Swiper>
                    </div>
                </div>
            </section>
            {/* Testimonial Section */}
            <TestimonialSection />

            {/* Carousel_one */}
            <section className="bg-light">
                <div className="ps-xl-5 pe-xl-5 wow fadeInLeft text-center" data-wow-delay="200ms">
                    <Swiper {...swiperClientConfig}>
                        <SwiperClientSlide />
                    </Swiper>
                </div>
            </section>
        </>
    )
}