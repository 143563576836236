import React, { useState, useEffect } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import Banner from '../Banner';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import WOW from 'wowjs';
import { Helmet } from "react-helmet";

const baseURL = 'https://outsourcebookkeeping.us/api/get-blogs'
const baseURLLocal = 'http://localhost/projects/Outsourcedbookkeeping/eosa-api/get-blogs';

export default function Blog() {
    const [blogData, setBlogData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const postsPerPage = 9;
    const [settingsData, setSettingsData] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        const wow = new WOW.WOW({ live: false });
        wow.init();
    }, [currentPage]);

    useEffect(() => {
        const fetchBlogData = async () => {
            const existingData = blogData.find(entry => entry.page === currentPage);
            if (existingData) {
                setIsLoading(false);
                return;
            }

            const blogLocalData = JSON.parse(localStorage.getItem('blogLocalData'));
            if (blogLocalData && currentPage === 1) {
                setBlogData(prevData => [...prevData, { page: currentPage, blog: blogLocalData.blog, total_count: blogLocalData.total_count }])
                setTotalPages(Math.ceil(blogLocalData.total_count / postsPerPage));
                setIsLoading(false);
                return;
            }
            try {
                const response = await axios.post(baseURLLocal, { page: currentPage });
                const newData = response.data;
                if (currentPage === 1) {
                    localStorage.setItem('blogLocalData', JSON.stringify(newData))
                }
                setBlogData(prevData => [...prevData, { page: currentPage, blog: newData.blog, total_count: newData.total_count }]);
                setTotalPages(Math.ceil(newData.total_count / postsPerPage));
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            }
        };

        fetchBlogData();
    }, [currentPage]);

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected + 1);
    };
    useEffect(() => {
        const cachedData = localStorage.getItem('settingsData');
        if (cachedData) {
            try {
                const parseData = JSON.parse(cachedData);
                setSettingsData(parseData);
            } catch (error) {
                console.error("Error Parsing Data", error);
                localStorage.removeItem('settingsData');
            }
        }
    }, []);
    // Meta Tags 
    const metaTitleSetting = settingsData.find(setting => setting.meta_key === 'setting_blog_meta_title');
    const metaTitle = metaTitleSetting ? metaTitleSetting.meta_value : '';

    const metaDescriptionSetting = settingsData.find(setting => setting.meta_key === 'setting_blog_meta_description');
    const metaDescription = metaDescriptionSetting ? metaDescriptionSetting.meta_value : '';

    const metaKeywordsSetting = settingsData.find(setting => setting.meta_key === 'setting_blog_meta_keywords');
    const metaKeywords = metaKeywordsSetting ? metaKeywordsSetting.meta_value : '';
    return (
        <>
            <Helmet>
                <title>Blog</title>
                <meta name="title" content={metaTitle} />
                <meta name="description" content={metaDescription} />
                <meta name="keywords" content={metaKeywords} />
                <meta name="image" content="../../img/banner/blog.webp" />
            </Helmet>
            <Banner
                imgUrl="../../img/banner/blog.webp"
                heading="Outsourced Bookkeeping Blog."
                button='GET A VIRTUAL CONSULTATION'
            />
            <section>
                <div className="container wow fadeInUp" data-wow-delay="300ms">
                    <h2 className='text-center' data-wow-delay="200ms">What Does a Bookkeeper Do?</h2>
                    {isLoading ? (
                        <ThreeDots /> // Render loader spinner when data is loading
                    ) : (
                        <div className="row ">
                            {blogData.map(pageData => (
                                pageData.page === currentPage && pageData.blog.map(blog => (
                                    <div className="col-lg-4 mt-3 mb-5" key={blog.id}>
                                        <div className="card blog-card">
                                            <img src="./img/images/bookkeeping-1024x682.jpg" alt="...." width="" />
                                            <div className="p-4 card-body">
                                                <p className="blog-date card-text text-main">March 15, 2024</p>
                                                <h3 className="card-title">{blog.title}</h3>
                                                <p className="card-text">{blog.except}</p>
                                            </div>
                                            <div className="d-flex p-4 fw-bold border-top px-1-6 px-sm-1-9 py-3 border-color-light-black justify-content-between">
                                                <Link to={blog.slug} className="link-underline  link-underline-opacity-0">
                                                    READ MORE
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ))}
                            {blogData.length > 0 && (
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="Next"
                                    onPageChange={(selected) => {
                                        handlePageClick(selected);
                                        // window.scrollTo(0, 0); // Scroll to top when pagination changes
                                    }}
                                    pageRangeDisplayed={5}
                                    pageCount={totalPages}
                                    forcePage={currentPage - 1}
                                    previousLabel="Previous"
                                    marginPagesDisplayed={2}
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    pageLinkClassName="page-link"
                                    nextLinkClassName="page-link"
                                    previousLinkClassName="page-link"
                                    breakClassName="page-item"
                                    disabledClassName="disabled"
                                    pageClassName="page-item"
                                />
                            )}
                        </div>
                    )}
                </div>
            </section>
        </>
    );
}
