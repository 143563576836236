import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Swal from 'sweetalert2';
import $ from "jquery";
import ReCAPTCHA from 'react-google-recaptcha';
import { ThreeDots } from 'react-loader-spinner';

var baseURL = 'https://outsourcebookkeeping.us/api/form-submit'
var baseLocalURL = 'http://localhost/projects/Outsourcedbookkeeping/eosa-api/form-submit'
var datanew = new FormData(($("#popup-form")[0]))
export default function ModalForm() {
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        datanew = new FormData(($("#popup-form")[0]))
        try {
            const response = await axios.post(baseURL, datanew); 
            setIsLoading(false)
            if (response.data.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: response.data.message
                });
            } else if (response.data.status === 103) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Warning',
                    text: response.data.message
                })
            } else if (response.data.status === 104) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: response.data.message
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: response.data.message
                });
            }
        } catch (error) { 
            setIsLoading(false)
            console.error('Error submitting form:', error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "An error Occured!!! Please try again later"
            });
        }
    };
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowModal(true);
        }, 10000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {/* Modal */}
            <Popup open={showModal} position="right center">
                <div className="container">
                    <form id="popup-form" className="bg-white p-4" onSubmit={handleSubmit} method='post' encType='multipart/form-data'>
                        <div className="d-flex justify-content-between align-items-center pb-4">
                            <h2 className="mb-2 mt-2">Let’s Start a Conversation</h2>
                            <button type="button" className="btn-close" onClick={handleCloseModal} aria-label="Close"></button>
                        </div>
                        <p className="text-main">We’d love to discuss how to elevate your business</p>
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control border border-success"
                                placeholder="Name"
                                name='name'
                                value={datanew.name}
                                required
                            />
                        </div>
                        <div className="mb-3">
                                <input
                                    type="text"
                                    className="form-control border border-success"
                                    placeholder="Email"
                                    name='email'
                                    value={datanew.email}
                                    required
                                />
                        </div>
                        <div className="mb-3">
                                <input
                                    type="text"
                                    className="form-control border border-success"
                                    placeholder="Phone Number"
                                    name='phoneNumber'
                                    value={datanew.phoneNumber}
                                    required
                                />
                        </div>
                        <div className="mb-3">
                            <textarea
                                className="form-control border border-success"
                                rows={4}
                                placeholder="Message"
                                name='message'
                                value={datanew.message}
                            />
                        </div>
                        {/* Add ReCAPTCHA component */}
                        <div className="pt-1">
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                            />
                        </div>
                        {isLoading && (
                           <ThreeDots
                           visible={true}
                           height="80"
                           width="80"
                           color="#008080"
                           radius="9"
                           ariaLabel="three-dots-loading"
                           wrapperStyle={{}}
                           wrapperClass=""
                           />
                        )}
                        <div className="pt-3 pb-3">
                            <button type="submit" className="form-control btn btn-main">Submit</button>
                        </div>
                    </form>
                </div>
            </Popup>
        </>
    );
}
