import React from 'react'
import { Swiper } from "./MyCarousel";
import { swiperTestimonialsConfig, SwiperTestimonialsSlide } from "./MyCarousel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons';


export default function TestimonialSection() {
    return (
        <>
            <section className="pt-0 pb-0">
                <div className="container-fluid bg-main">
                    <div className="row">
                        <div
                            className="col-lg-6 text-white wow fadeIn align-self-center"
                            style={{ padding: "60px 46px" }}
                            data-wow-delay="600ms"
                        >
                            <h2 className="text-main">Engage the Bookkeeping Experts </h2>
                            <p>
                                So why wait? Exceptional service starts at OutsourceBookkeeping℠. If you
                                are looking for an experienced and reliable team to take care of
                                your bookkeeping needs in San Antonio, TX, look no further than
                                Outsource Bookkeeping.{" "}
                                <span className="text-main">Contact us today </span> to learn more
                                about how we can help you achieve meaningful growth for your
                                business.
                            </p>
                            <p className="h4">
                                <FontAwesomeIcon icon={faCheckDouble} /> Professionalism
                            </p>
                            <p className="h4">
                                <FontAwesomeIcon icon={faCheckDouble} /> Responsiveness
                            </p>
                            <p className="h4">
                                <FontAwesomeIcon icon={faCheckDouble} /> Quality Service
                            </p>
                        </div>
                        <div
                            className="col-lg-6 wow fadeInRight"
                            style={{
                                backgroundColor: "#008080",
                                paddingTop: 50,
                                paddingBottom: 50
                            }}
                            data-wow-delay="200ms"
                        >
                            <div className="row mb-4">
                                <div className="text-center text-white">
                                    <h5 className="text-main">Kind Words</h5>
                                    <h4 className="h3 text-white">Client Testimonials</h4>
                                </div>
                            </div>
                            <Swiper {...swiperTestimonialsConfig}>
                                <SwiperTestimonialsSlide />
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
