import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { register } from 'swiper/element/bundle';
import { Link } from 'react-router-dom'
// Client Carousel
export const swiperClientConfig = {
    loop: true,
    slidesPerView: 5,
    autoplay: {
        delay: 2500, // Adjust the delay as needed
        disableOnInteraction: false,
    },
    breakpoints: {
        300: { slidesPerView: 1, spaceBetween: 10 },
        440: { slidesPerView: 2, spaceBetween: 20 },
        768: { slidesPerView: 3, spaceBetween: 30 },
        1024: { slidesPerView: 5, spaceBetween: 40 },
    }
}

export function SwiperClientSlide() {
    const [settingsData, setSettingsData] = useState([]);

    useEffect(() => {
        const cachedData = localStorage.getItem('settingsData');
        if (cachedData) {
            try {
                const parsedData = JSON.parse(cachedData);
                setSettingsData(parsedData);
            } catch (error) {
                console.error('Error parsing cached data:', error);
                localStorage.removeItem('settingsData');
            }
        }
    }, []);

    const clientSetting = settingsData.find(setting => setting.meta_key === 'setting_software_logos');
    const clientImages = clientSetting ? clientSetting.meta_value : '';

    // Ensure REACT_APP_IMG_PATH is set properly
    const imagePath = process.env.REACT_APP_IMG_PATH || '';

    // Check if clientImages is not empty before rendering
    if (!clientImages) {
        return null; // or render a placeholder image
    }

    // Split clientImages into an array of image filenames
    const imageFilenames = clientImages.split(',');

    // Map each image filename to a SwiperSlide component
    const swiperSlides = imageFilenames.map((filename, index) => (
        <SwiperSlide key={index} >
            <img src={`${imagePath}uploads/${filename}`} alt="" />
        </SwiperSlide>
    ));
    // Concatenate swiperSlides with itself to run the loop twice
    const swiperSlidesTwice = swiperSlides.concat(swiperSlides);

    return swiperSlidesTwice;
}

// Carousel Services
export const swiperCarouselServicesConfig = {
    loop: true,
    // slidesPerView: 2,
    // autoplay: {
    //     delay: 2500,
    //     disableOnInteraction: false,
    // },
    breakpoints: {
        300: { slidesPerView: 1, spaceBetween: 10 },
        440: { slidesPerView: 2, spaceBetween: 20 },
        768: { slidesPerView: 3, spaceBetween: 30 },
        1024: { slidesPerView: 3, spaceBetween: 40 },
    }
}
export const swiperCarouselServicesSlide = [
    <>
        <SwiperSlide key="1">
            <div className='item'>
                <Link to="/bookkeeping-services">
                    <img
                        src="img/icon/managed-bookkeeping.png"
                        alt=""
                    />
                    <div className="text-center mt-2">
                        <p>Bookkeeping Services</p>
                    </div>
                </Link>
            </div>
        </SwiperSlide>
        <SwiperSlide key="2">
            <div className='item'>
                <Link to="/payroll-services">
                    <img
                        src="img/icon/customer-service.png"
                        alt=""
                    />
                    <div className="text-center mt-2">
                        <p>Payroll Services</p>
                    </div>
                </Link>
            </div>
        </SwiperSlide>

        <SwiperSlide key="3">
            <div className='item'>
                <Link to="/tax-preparation-services">
                    <img src="img/icon/tax.png" alt="" />
                    <div className="text-center mt-2">
                        <p>Tax Preparation Service</p>
                    </div>
                </Link>
            </div>
        </SwiperSlide>
        <SwiperSlide key="4">
            <div className='item'>
                <Link to="/accounting-services">
                    <img
                        src="img/icon/accounting.png"
                        alt=""
                    />
                    <div className="text-center mt-2">
                        <p>Accounting Services</p>
                    </div>
                </Link>
            </div>
        </SwiperSlide>
        <SwiperSlide key="5">
            <div className='item'>
                <Link to="/accounts-payable-services">
                    <img
                        src="img/icon/payables.png"
                        alt=""
                    />
                    <div className="text-center mt-2">
                        <p>Accounts Payable Services</p>
                    </div>
                </Link>
            </div>
        </SwiperSlide>
        <SwiperSlide key="6">
            <div className='item'>
                <Link to="/accounts-receivable-services">
                    <img
                        src="img/icon/account.png"
                        alt=""
                    />
                    <div className="text-center mt-2">
                        <p>Accounts Receivable Services</p>
                    </div>
                </Link>
            </div>
        </SwiperSlide>
        <SwiperSlide key="7">
            <div className='item'>
                <Link to="/data-conversion-services">
                    <img
                        src="img/icon/controller-services.png"
                        alt=""
                    />
                    <div className="text-center mt-2">
                        <p>Data Conversion Services</p>
                    </div>
                </Link>
            </div>
        </SwiperSlide>
        <SwiperSlide key="8">
            <div className='item'>
                <Link to="/services-for-cpa-ea">
                    <img
                        src="img/icon/cpa.png"
                        alt=""
                    />
                    <div className="text-center mt-2">
                        <p>Services for CPA and EA</p>
                    </div>
                </Link>
            </div>
        </SwiperSlide>
    </>
]

// Services Carousel
export const swiperServicesConfig = {
    loop: true,
    slidesPerView: 4,
    autoplay: {
        delay: 2500,
        disableOnInteraction: false,
    },
    breakpoints: {
        300: { slidesPerView: 1, spaceBetween: 10 },
        440: { slidesPerView: 2, spaceBetween: 20 },
        768: { slidesPerView: 3, spaceBetween: 30 },
        1024: { slidesPerView: 4, spaceBetween: 40 },
    }
}
export const swiperServicesSlide = [
    <>
        <SwiperSlide key="one">
            <div className='item'>
                <Link to="/bookkeeping-services">
                    <img
                        src="img/icon/managed-bookkeeping.png"
                        alt=""
                    />
                    <div className="text-center mt-2">
                        <p>Bookkeeping Services</p>
                    </div>
                </Link>
            </div>
        </SwiperSlide>
        <SwiperSlide key="two">
            <div className='item'>
                <Link to="/payroll-services">
                    <img
                        src="img/icon/customer-service.png"
                        alt=""
                    />
                    <div className="text-center mt-2">
                        <p>Payroll Services</p>
                    </div>
                </Link>
            </div>
        </SwiperSlide>

        <SwiperSlide key="three">
            <div className='item'>
                <Link to="/tax-preparation-services">
                    <img src="img/icon/tax.png" alt="" />
                    <div className="text-center mt-2">
                        <p>Tax Preparation Service</p>
                    </div>
                </Link>
            </div>
        </SwiperSlide>
        <SwiperSlide key="four">
            <div className='item'>
                <Link to="/accounting-services">
                    <img
                        src="img/icon/accounting.png"
                        alt=""
                    />
                    <div className="text-center mt-2">
                        <p>Accounting Services</p>
                    </div>
                </Link>
            </div>
        </SwiperSlide>
        <SwiperSlide key="five">
            <div className='item'>
                <Link to="/accounts-payable-services">
                    <img
                        src="img/icon/payables.png"
                        alt=""
                    />
                    <div className="text-center mt-2">
                        <p>Accounts Payable Services</p>
                    </div>
                </Link>
            </div>
        </SwiperSlide>
        <SwiperSlide key="six">
            <div className='item'>
                <Link to="/accounts-receivable-services">
                    <img
                        src="img/icon/account.png"
                        alt=""
                    />
                    <div className="text-center mt-2">
                        <p>Accounts Receivable Services</p>
                    </div>
                </Link>
            </div>
        </SwiperSlide>
        <SwiperSlide key="seven">
            <div className='item'>
                <Link to="/data-conversion-services">
                    <img
                        src="img/icon/controller-services.png"
                        alt=""
                    />
                    <div className="text-center mt-2">
                        <p>Data Conversion Services</p>
                    </div>
                </Link>
            </div>
        </SwiperSlide>
        <SwiperSlide key="eight">
            <div className='item'>
                <Link to="/services-for-cpa-ea">
                    <img
                        src="img/icon/cpa.png"
                        alt=""
                    />
                    <div className="text-center mt-2">
                        <p>Services for CPA and EA</p>
                    </div>
                </Link>
            </div>
        </SwiperSlide>
    </>
]

// Testimonial Carousel
export const swiperTestimonialsConfig = {
    loop: true,
    autoplay: {
        delay: 5000,
        disableOnInteraction: true,
    }
}
export function SwiperTestimonialsSlide() {
    const baseURL = 'https://outsourcebookkeeping.us/api/get-testimonials'
    const baseLocalURL = 'http://localhost/projects/Outsourcedbookkeeping/eosa-api/get-testimonials'
    const [testimonialData, setTestimonialData] = useState(null);
    useEffect(() => {
        const fetchTestimonialData = async () => {
            try {
                const response = await axios.post(baseLocalURL, {});
                const data = response.data;
                console.log(data)
                setTestimonialData(data);
                // Save data to localStorage
            } catch (error) {
                console.error('Error Fetching Data:', error);
            }
        };
        fetchTestimonialData();
    }, []);
    return (
        <>
            {testimonialData ? (
                <>
                {testimonialData && (
                        testimonialData.map((testimonial, index) => (
                            <SwiperSlide key={index}>
                                <div className="testimonial-card container bg-white d-flex justify-content-center rounded w-75 p-4">
                                    <div className="item">
                                        <img className='pb-3' src='img/icon/user.png' alt="User Icon" />
                                        <p>{testimonial.description}</p>
                                        <p className='author'>{testimonial.name}</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))
                    )}
                </>
            ) : (
                null
            )}
        </>
    )
}
export function Swiper(props) {
    const swiperRef = useRef(null);
    const {
        children,
        ...rest
    } = props;

    useEffect(() => {
        register();
        const params = {
            ...rest
        };

        // Assign it to swiper element
        Object.assign(swiperRef.current, params);

        // initialize swiper
        swiperRef.current.initialize();
    }, []);

    return (
        <swiper-container init="false" ref={swiperRef}>
            {children}
        </swiper-container>
    );
}
export function SwiperSlide(props) {
    const {
        children,
        ...rest
    } = props;

    return (
        <swiper-slide {...rest}>
            {children}
        </swiper-slide>
    );
}