import './App.css';
import { useEffect, useState } from 'react';
import Header from './components/pages/Header';
import Footer from './components/pages/Footer';
// Pages

import Home from './components/pages/Home.js';
import About from './components/pages/About.js';
import Services from './components/pages/services/Services.js';
import AccountingServices from './components/pages/services/AccountingServices.js';
import BookkeepingServices from './components/pages/services/BookkeepingServices.js';
import PayrollServices from './components/pages/services/PayrollServices.js';
import AccountsPayableServices from './components/pages/services/AccountsPayableServices.js';
import AccountsReceivableServices from './components/pages/services/AccountsReceivableServices.js';
import TaxPreparationServices from './components/pages/services/TaxPreparationServices.js';
import ServicesForCpa from './components/pages/services/ServicesForCpa.js';
import DataConversionServices from './components/pages/services/DataConversionServices.js';
import PropertyAccounting from './components/pages/services/PropertyAccounting.js';
import IndustriesWeServe from './components/pages/services/IndustriesWeServe.js';
import Blog from './components/pages/Blog.js';
import Gallery from './components/pages/Gallery.js';
import Faqs from './components/pages/Faqs.js';
import GetQuoteForm from './components/pages/GetQuoteForm.js';
import ContactUs from './components/pages/ContactUs.js';
import HowItWorks from './components/pages/HowItWorks.js';
import RequestQuote from './components/pages/RequestQuote.js';
import BlogSingle from './components/pages/BlogSingle.js';
import Construction from './components/pages/Construction.js';
// CSS
import './styles/animate.css';
import './styles/style.css';

// Scripts
import './js/main.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";
import ModalForm from './components/ModalForm.js';
import SiteSettings from './components/SiteSettings.js';
import axios from 'axios';
import { Helmet } from 'react-helmet';
const ipURL = 'https://outsourcebookkeeping.us/api/get-ip';
const ipLocalURL = 'http://localhost/projects/Outsourcedbookkeeping/eosa-api/get-ip';

function App() {
    const [settingsData, setSettingsData] = useState([]);
    const [userIP, setUserIP] = useState(null);

    useEffect(() => {
        const cachedData = localStorage.getItem('settingsData');
        if (cachedData) {
            try {
                const parsedData = JSON.parse(cachedData);
                setSettingsData(parsedData);
            } catch (error) {
                console.error('Error parsing cached data:', error);
                localStorage.removeItem('settingsData');
            }
        }
    }, []);
    useEffect(() => {
        const fetchIP = async () => {
            try {
                const response = await axios.post(ipLocalURL);
                // console.log('response '+response);
                setUserIP(response.data);

            } catch (error) {
                console.log('Error fetching IP:', error);
            }
        };

        fetchIP();
    }, []);
    const faviconSettings = settingsData.find(setting => setting.meta_key === 'setting_favicon');
    const favicon = faviconSettings ? faviconSettings.meta_value : '';
    return (
        <>
            <Helmet>
                <link rel="icon" href={process.env.REACT_APP_IMG_PATH+"uploads/"+favicon} />
            </Helmet>
            <Router>
                <ModalForm />
                <SiteSettings />
                <Header />
                <Routes>
                    <Route exact path="/" element={<Home />}></Route>
                    <Route exact path="/about" element={<About />}></Route>
                    <Route exact path="/services" element={<Services />}></Route>
                    <Route exact path="/accounting-services" element={<AccountingServices />}></Route>
                    <Route exact path="/bookkeeping-services" element={<BookkeepingServices />}></Route>
                    <Route exact path="/payroll-services" element={<PayrollServices />}></Route>
                    <Route exact path="/accounts-payable-services" element={<AccountsPayableServices />}></Route>
                    <Route exact path="/accounts-receivable-services" element={<AccountsReceivableServices />}></Route>
                    <Route exact path="/tax-preparation-services" element={<TaxPreparationServices />}></Route>
                    <Route exact path="/services-for-cpa-ea" element={<ServicesForCpa />}></Route>
                    <Route exact path="/data-conversion-services" element={<DataConversionServices />}></Route>
                    <Route exact path="/property-accounting" element={<PropertyAccounting />}></Route>
                    <Route exact path="/industries-we-serve" element={<IndustriesWeServe />}></Route>
                    <Route exact path="/gallery" element={<Gallery />}></Route>
                    <Route exact path="/faqs" element={<Faqs />}></Route>
                    <Route exact path="/blog" element={<Blog />}></Route>
                    <Route exact path="/blog/:slug" element={<BlogSingle />}></Route>
                    <Route exact path="/get-quote" element={<GetQuoteForm />}></Route>
                    <Route exact path="/contact-us" element={<ContactUs />}></Route>
                    <Route exact path="/how-it-works" element={<HowItWorks />}></Route>
                    <Route exact path="/request-a-quote" element={<RequestQuote />}></Route>
                </Routes>
                <Footer />
            </Router>
            
        </>
    );
}

export default App;
