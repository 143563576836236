import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'

export default function Header() {
    const [settingsData, setSettingsData] = useState([]);

    useEffect(() => {
        const cachedData = localStorage.getItem('settingsData');
        if (cachedData) {
            try {
                const parsedData = JSON.parse(cachedData);
                setSettingsData(parsedData);
            } catch (error) {
                console.error('Error parsing cached data:', error);
                localStorage.removeItem('settingsData');
            }
        } 
    }, []);
    //Logo URL
    const logoSetting = settingsData.find(setting => setting.meta_key === 'setting_logo');
    const logoUrl = logoSetting ? logoSetting.meta_value : ''; 

    // Phone Number 
    const phoneSetting = settingsData.find(setting => setting.meta_key === 'setting_phone');
    const phoneNumber = phoneSetting ? phoneSetting.meta_value : ''; 

    return (
        <>
            <header className="container-fluid bg-white fixed-top">
                <nav className="navbar navbar-expand-xl p-0">
                    <div className="container-fluid">
                        <NavLink className="navbar-brand pe-xxl-4" to="/">
                               <img className='logo' src={process.env.REACT_APP_IMG_PATH+"uploads/"+logoUrl} />
                        </NavLink>
                        <button className="navbar-toggler bg-main" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="mynavbar">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <NavLink className="nav-NavLink" to="/about">ABOUT</NavLink>
                                </li>
                                <li className="nav-item dropdown">
                                    <NavLink className="nav-NavLink" to="/services" id="" role="button">
                                        SERVICES
                                    </NavLink>
                                    <div className="dropdown-menu dropdown-menu-hover" aria-labelledby="">
                                        <NavLink className="dropdown-item" to="/accounting-services">Accounting Services</NavLink>
                                        <NavLink className="dropdown-item" to="/bookkeeping-services">Bookkeeping Services</NavLink>
                                        <NavLink className="dropdown-item" to="/payroll-services">Payroll Services</NavLink>
                                        <NavLink className="dropdown-item" to="/accounts-payable-services">Accounts Payable Services</NavLink>
                                        <NavLink className="dropdown-item" to="/accounts-receivable-services">Accounts Receivable Services</NavLink>
                                        <NavLink className="dropdown-item" to="/tax-preparation-services">Tax Preparation Services</NavLink>
                                        <NavLink className="dropdown-item" to="/services-for-cpa-ea">Services for CPA and EA</NavLink>
                                        <NavLink className="dropdown-item" to="/data-conversion-services">Data Conversion Services</NavLink>
                                        <NavLink className="dropdown-item" to="/data-conversion-services">Data Conversion Services</NavLink>
                                        <NavLink className="dropdown-item" to="/data-conversion-services">Data Conversion Services</NavLink>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-NavLink" to="/gallery">GALLERY</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-NavLink" to="/faqs">FAQ'S</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-NavLink" to="/request-a-quote">REQUEST A QUOTE</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-NavLink" to="/how-it-works">HOW IT WORKS</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-NavLink" to="/blog">BLOG</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-NavLink" to="/contact-us">CONTACT</NavLink>
                                </li>
                            </ul>
                            <ul className="navbar-nav ms-auto" id="header_contact_us">
                                <li className="nav-item ms-xl-5">
                                    <form className="navbar-collapse justify-content-lg-end" id="">
                                        <div className='phone-header'>
                                            <img src="img/icon/telephone.png" alt="" />
                                            <span className="ms-3">{phoneNumber}<br />Have Question ?</span>
                                        </div>
                                        <div className="btn-group">
                                            <NavLink className="nav-NavLink" to="/contact-us"><button type="button" id="sign_in_btn" className="btn-main ms-4">CONTACT US</button></NavLink>
                                        </div>
                                    </form>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}
