import React, { useState, useEffect } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import Banner from '../Banner';
import axios from 'axios';
import WOW from 'wowjs';
import { Helmet } from "react-helmet";
import Fancybox from './Fancybox';
const baseLocalURL = "http://localhost/projects/Outsourcedbookkeeping/eosa-api/get-galleries";

export default function Gallery() {
    const [galleryData, setGalleryData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [galleryImages, setGalleryImages] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        const wow = new WOW.WOW({ live: false });
        wow.init();
    }, []);
    useEffect(() => {
        const fetchGalleries = async () => {
            try {
                const response = await axios.post(baseLocalURL);
                const data = response.data;
                console.log(data);
                setGalleryData(data.galleries);
                setGalleryImages(data.gallery_images);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching data", error);
                setIsLoading(false);
            }
        };

        fetchGalleries();
    }, []); // Empty dependency array ensures this runs once on mount

    return (
        <>
            <Helmet>
                <title>Gallery</title>
                <meta name="title" content='Gallery' />
                <meta name="description" content='Description' />
                <meta name="keywords" content='Keyword' />
                <meta name="image" content="../../img/banner/blog.webp" />
            </Helmet>
            <Banner
                imgUrl="../../img/banner/blog.webp"
                heading="Outsourced Bookkeeping Gallery."
                button='GET A VIRTUAL CONSULTATION'
            />
            <section>
                <div className="container wow fadeInUp" data-wow-delay="300ms">
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        {isLoading ? (
                            <ThreeDots /> // Render loader spinner when data is loading
                        ) : (
                            galleryData.map((gallery, index) => (
                                <li className="nav-item" role="presentation" key={index}>
                                    <button
                                        className={`nav-link ${index === 0 ? 'active' : ''}`}
                                        id={`tab-${index}`}
                                        data-bs-toggle="pill"
                                        data-bs-target={`#pills-home-${index}`}
                                        type="button"
                                        role="tab"
                                        aria-controls={`pills-home-${index}`}
                                        aria-selected={index === 0 ? 'true' : 'false'}
                                    >
                                        {gallery.gallery_name}
                                    </button>
                                </li>
                            ))
                        )}
                    </ul>

                    <div className="tab-content" id="pills-tabContent">
                        {isLoading ? (
                            <ThreeDots /> // Render loader spinner when data is loading
                        ) : (
                            galleryData.map((gallery, galleryIndex) => (
                                <div
                                    key={galleryIndex}
                                    className={`tab-pane fade ${galleryIndex === 0 ? 'show active' : ''}`}
                                    id={`pills-home-${galleryIndex}`}
                                    role="tabpanel"
                                    aria-labelledby={`tab-${galleryIndex}`}
                                >
                                    <Fancybox
                                        options={{
                                            Carousel: {
                                                infinite: false,
                                            },
                                        }}
                                    >
                                        <div className="gallery-grid d-flex" style={{ flexWrap: 'wrap', gap: '10px' }}>
                                            {galleryImages
                                                .filter(image => image.gallery_id === gallery.id)
                                                .map((image, imgIndex) => (
                                                    <a
                                                        key={imgIndex}
                                                        data-fancybox={`gallery-${galleryIndex}`}
                                                        href={`${process.env.REACT_APP_IMG_PATH}gallery/${image.img_path}`}
                                                        data-caption={image.img_caption}
                                                    >
                                                        {image.type === 'image'?(
                                                            <img
                                                                src={`${process.env.REACT_APP_IMG_PATH}gallery/${image.img_path}`}
                                                                alt={image.img_caption}
                                                                width="300px"
                                                                height="auto"
                                                            />
                                                         ) : (
                                                            <img
                                                                src={`${process.env.REACT_APP_IMG_PATH}gallery/video-thumb.png`}
                                                                alt={image.img_caption}
                                                                width="300px"
                                                                height="auto"
                                                            />
                                                        )}

                                                    </a>
                                                ))}
                                        </div>
                                    </Fancybox>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}