import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import { swiperClientConfig, Swiper, SwiperClientSlide, swiperCarouselServicesConfig, swiperCarouselServicesSlide } from "../../MyCarousel";
import Banner from "../../Banner";
import Form from '../../Form';
import WOW from 'wowjs';
import { Helmet } from "react-helmet";

export default function Services() {
    const [settingsData, setSettingsData] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        const wow = new WOW.WOW({ live: false });
        wow.init();
    }, []);
    useEffect(() => {
        const cachedData = localStorage.getItem('settingsData');
        if (cachedData) {
            try {
                const parseData = JSON.parse(cachedData);
                setSettingsData(parseData);
            } catch (error) {
                console.error("Error Parsing Data", error);
                localStorage.removeItem('settingsData');
            }
        }
    }, []);
    // Meta Tags 
    const metaTitleSetting = settingsData.find(setting => setting.meta_key === 'setting_services_meta_title');
    const metaTitle = metaTitleSetting ? metaTitleSetting.meta_value : '';

    const metaDescriptionSetting = settingsData.find(setting => setting.meta_key === 'setting_services_meta_description');
    const metaDescription = metaDescriptionSetting ? metaDescriptionSetting.meta_value : '';

    const metaKeywordsSetting = settingsData.find(setting => setting.meta_key === 'setting_services_meta_keywords');
    const metaKeywords = metaKeywordsSetting ? metaKeywordsSetting.meta_value : '';
    return (
        <>
            <Helmet>
                <title>Services</title>
                <meta name="title" content={metaTitle} />
                <meta name="description" content={metaDescription} />
                <meta name="keywords" content={metaKeywords} />
                <meta name="image" content="../../img/banner/banners-3.webp" />
            </Helmet>

            <Banner imgUrl="../../img/banner/banners-3.webp" heading="More than your typical Bookkeeping Firm." button='GET A VIRTUAL CONSULTATION' />
            {/* What Does a Bookkeeper Do? */}
            <section
                className="wow fadeInUp pb-0"
                data-wow-delay="200ms"
                style={{ paddingTop: "200px !important" }}
            >
                <div className="container">
                    <div className="row text-center">
                        <div className="col">
                            <h2>What Does a Bookkeeper Do?</h2>
                            <p>
                                Our team has years of experience working with businesses of all
                                sizes, and we pride ourselves on our ability to provide quality
                                service that helps you keep your finances organized and grow your
                                business. Whether you need ongoing bookkeeping support or just
                                occasional assistance with specific tasks, we have the expertise and
                                tools necessary to get the job done right.
                            </p>
                            <p>
                                A mature business relies on smart strategies, planning in advance,
                                and attention to detail to fulfill its mission’s full potential. A
                                business’s success comes down to how it maneuvers its cash flow from
                                the top, down — and most importantly — the good folks who design and
                                execute that strategy. Welcome to OutsourceBookkeeping. We are here for
                                you.
                            </p>
                            <div className="mt-4">
                                <Link to="/services">
                                    <button type="button" className="btn btn-main">
                                        SEE OUR SERVICES
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Why Consider Outsource Bookkeeping Services */}
            <section style={{ paddingTop: "200px !important" }}>
                <div className="container">
                    <div className="row bg-light">
                        <div
                            className="col-md-8 col-lg-4 text-center wow fadeInLeft"
                            data-wow-delay="600ms"
                        >
                            <img
                                className="why_choose_us_img"
                                src="img/images/Business-Owner-Why-Consider.jpg"
                                alt="...."
                                width="80%"
                            />
                        </div>
                        <div
                            className="col-md-12 col-lg-8 col-sm-12 p-5 rounded wow fadeInRight"
                            data-wow-delay="200ms"
                        >
                            <h3>Why choose Outsource Bookkeeping?</h3>
                            <p>
                                We strive to build long-term relationships. That’s why we listen
                                first — then respond to your needs and problem-solve. At Outsource
                                Bookkeeping, we provide you and your company with the finest client
                                service in San Antonio. Whether you are looking to balance your
                                budget or seeking meaningful insight for your company, we offer
                                comprehensive assistance. Our qualified staff is available
                                year-round to help you when you need it most.
                            </p>
                            <Link to="/contact-us">
                                <button type="button" className="btn btn-main">
                                    START A CONVERSATION
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* Our Attention To Detail */}
            {/* =================================== */}
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 wow fadeIn" data-wow-delay="600ms">
                            <h3 className="">
                                Our Attention To Detail And Client-Focused Staff Are What Set Us
                                Apart. Let Outsource Bookkeeping Help You Streamline Your Books So You
                                Can Get Back To Business.
                            </h3>
                            <p>
                                {" "}
                                We provide a wide range of bookkeeping and software solutions to
                                amplify your business. Whether you are a mature organization seeking
                                to free up time spent on books or a large company that needs to
                                track finances to prepare for investors and other outside capital,
                                the team at Outsource Bookkeeping can provide the outstanding service
                                that you need to continue moving forward.
                            </p>
                            <Link to="/contact-us">
                                <button type="button" className="btn-main">
                                    GET STARTED
                                </button>
                            </Link>
                        </div>
                        <div className="col-md-6 pt-5 p-lg-1 wow fadeIn" data-wow-delay="800ms">
                            <img src="img/images/services1.jpeg" width="100%" height=" 100%" />
                        </div>
                    </div>
                </div>
            </section>

            {/* Engage the Bookkeeping Experts */}
            <section className="pt-0 pb-0">
                <div className="container-fluid bg-main">
                    <div className="row justify-content-end">
                        <div
                            className="col-lg-4 ps-0 text-white wow fadeIn align-self-center text-center"
                            style={{ padding: "60px 46px" }}
                            data-wow-delay="600ms"
                        >
                            <h3 className="text-main h1">Other Services Includes:</h3>
                        </div>
                        <div
                            className="col-lg-8 wow fadeInRight bg-secondary"
                            style={{ paddingTop: 50, paddingBottom: 50 }}
                            data-wow-delay="200ms"
                        >
                            <div className="container bg-white p-4 text-center rounded w-75">
                                <div
                                    id=""
                                    className="mt-4 p-2 mx-auto"
                                >
                                    <Swiper {...swiperCarouselServicesConfig}>
                                        {swiperCarouselServicesSlide}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Our Attention To Detail */}
            {/* =================================== */}
            <section className="bg-light">
                <div className="container">
                    <h2 data-wow-delay="200ms">Check Our FAQ's</h2>
                    <div
                        className="accordion wow fadeInUp"
                        data-wow-delay="200ms"
                        id="accordionPanelsStayOpenExample"
                    >
                        <div className="accordion-item mb-3">
                            <h2
                                className="accordion-header mt-0 d-flex"
                                id="panelsStayOpen-headingOne"
                            >
                                <button
                                    className="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#panelsStayOpen-collapseOne"
                                    aria-expanded="true"
                                    aria-controls="panelsStayOpen-collapseOne"
                                >
                                    <h4 className='text-main'>Accordion Item #1</h4>
                                </button>
                            </h2>
                            <div
                                id="panelsStayOpen-collapseOne"
                                className="accordion-collapse collapse show"
                                aria-labelledby="panelsStayOpen-headingOne"
                            >
                                <div className="accordion-body">
                                    <strong>This is the first item's accordion body.</strong> It is
                                    shown by default, until the collapse plugin adds the appropriate
                                    classes that we use to style each element. These classes control
                                    the overall appearance, as well as the showing and hiding via CSS
                                    transitions. You can modify any of this with custom CSS or
                                    overriding our default variables. It's also worth noting that just
                                    about any HTML can go within the <code>.accordion-body</code>,
                                    though the transition does limit overflow.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item mb-3">
                            <h2
                                className="accordion-header d-flex"
                                id="panelsStayOpen-headingTwo"
                            >
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#panelsStayOpen-collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="panelsStayOpen-collapseTwo"
                                >
                                    <h4 className='text-main'>Accordion Item #2</h4>
                                </button>
                            </h2>
                            <div
                                id="panelsStayOpen-collapseTwo"
                                className="accordion-collapse collapse"
                                aria-labelledby="panelsStayOpen-headingTwo"
                            >
                                <div className="accordion-body">
                                    <strong>This is the second item's accordion body.</strong> It is
                                    hidden by default, until the collapse plugin adds the appropriate
                                    classes that we use to style each element. These classes control
                                    the overall appearance, as well as the showing and hiding via CSS
                                    transitions. You can modify any of this with custom CSS or
                                    overriding our default variables. It's also worth noting that just
                                    about any HTML can go within the <code>.accordion-body</code>,
                                    though the transition does limit overflow.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item mb-3">
                            <h2
                                className="accordion-header d-flex"
                                id="panelsStayOpen-headingThree"
                            >
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#panelsStayOpen-collapseThree"
                                    aria-expanded="false"
                                    aria-controls="panelsStayOpen-collapseThree"
                                >
                                    <h4 className='text-main'>Accordion Item #3</h4>
                                </button>
                            </h2>
                            <div
                                id="panelsStayOpen-collapseThree"
                                className="accordion-collapse collapse"
                                aria-labelledby="panelsStayOpen-headingThree"
                            >
                                <div className="accordion-body">
                                    <strong>This is the third item's accordion body.</strong> It is
                                    hidden by default, until the collapse plugin adds the appropriate
                                    classes that we use to style each element. These classes control
                                    the overall appearance, as well as the showing and hiding via CSS
                                    transitions. You can modify any of this with custom CSS or
                                    overriding our default variables. It's also worth noting that just
                                    about any HTML can go within the <code>.accordion-body</code>,
                                    though the transition does limit overflow.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Investing In Client Relationships */}
            <section className="bg-secondary pb-1 pt-3">
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div
                            className="col-lg-6 wow fadeInUp text-white"
                            data-wow-delay="200ms"
                        >
                            <div
                                className="pe-4 text-center"
                                id="Investing_In_Client_Relationships"
                            >
                                <h2 className="text-main mt-5">
                                    Investing In Client Relationships to Reinforce Your Managed
                                    Bookkeeping Services.
                                </h2>
                                <p>
                                    xperienced guidance, and seamless client relationships; we don’t
                                    just handle your bookkeeping needs, we get it done with
                                    unparalleled agility and consistent correspondence with you and
                                    your team. We are your local, approachable bookkeeping team.
                                </p>
                                <Link to="/about">
                                    <button type="button" className="btn btn-main mt-2">
                                        READ ABOUT US
                                    </button>
                                </Link>
                            </div>
                        </div>
                        {/* Form Component */}
                        <Form />
                    </div>
                </div>
            </section>

            {/* Carousel_one */}
            <section className="text-center">
                <div className="ps-xl-5 pe-xl-5 wow fadeInLeft" data-wow-delay="200ms">
                    <Swiper {...swiperClientConfig}>
                        <SwiperClientSlide />
                    </Swiper>
                </div>
            </section>

            <div className="bg-secondary p-2 wow fadeInLeft" data-wow-delay="200ms">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-md-6 pt-1">
                            <img
                                src="img/logo/white-logo.png"
                                width="100%"
                                height="50px"
                            />
                        </div>
                        <div className="col-xl-6 col-md-6 pt-3 text-white h5 text-center align-self-center">
                            <p>We are a Bookkeeper for QuickBooks® software in San Antonio</p>
                        </div>
                        <div className="col-xl-3 pt-2 pb-2 text-center align-self-center">
                            <Link to="/contact-us">
                                <button type="button" className="btn btn-main">
                                    REQUEST AN ESTIMATE
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* Engage the Bookkeeping Experts */}
            <section className="bg-dark">
                <div className="container">
                    <div className="row">
                        <div
                            className="col-lg-12 text-white wow fadeIn"
                            data-wow-delay="600ms"
                            style={{ padding: "60px 46px" }}
                        >
                            <h2 className='main-head'>Next Steps </h2>
                            <p>
                                If you’re feeling overwhelmed by bookkeeping and finances, don’t
                                worry. You can take the hassle out of managing your bookkeeping
                                process by hiring a professional to do it for you. Professional
                                bookkeepers can help you stay on top of your expenses, budget, and
                                taxes so that you can focus on what you’re good at – running your
                                business. Contact us today to get started!
                            </p>
                            <Link to="/contact-us">
                                <button type="button" className="btn btn-main">
                                    CONTACT US
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}
